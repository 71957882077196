import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'expended-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit,AfterViewInit {

  constructor() { }
  @Input() param;
  @Output() paramChange=new EventEmitter<any>();
  @Input() isOpen = false;
  @Output() isOpenChange=new EventEmitter<any>();
  @Output() onSearch=new EventEmitter<any>();
  @Output() onSearchClosed=new EventEmitter<any>();
  @ViewChild('search') searchElement: ElementRef;
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.searchElement.nativeElement.focus();
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if(event.target.id!='searchBoxInput' && event.target.id!='searchBoxIconClose' && event.target.id!='gridSerchIcon')
    {
        this.onClose();
    }
    // console.log("event Fired",event.target.id)
  }
  onClose(){
    setTimeout(() => {
      this.isOpenChange.emit(false);
      //this.onSearchClosed.emit(false);
    }, 0);
  }
  onOpen(){
    this.isOpenChange.emit(true);
  }
  searchClicked(){
      this.onSearch.emit(this.param?this.param:'');
      setTimeout(() => {
        this.param='';
      }, 0);
      //this.isOpenChange.emit(false);
  }
}
