import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { ScreenRenderer, V1UtilitiesService } from './v1-utilities.service';
import { Observable, of, from, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { concatMap, expand, map, observeOn } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { LoaderService } from './loader.service';
import { AppInitService, menuMapping } from './app-init.service';

@Injectable({
  providedIn: 'root'
})
export class V1MenuService {
  public magDashboard: boolean = false;
  public sphereDashboard: boolean = true;
  //v1-Menus in v3-formated object
  public menu: clsMenu = new clsMenu();
  //this event is fired when All prerequisites requests are successfull and parsed on screen(Menus/Accounts/Clients) befor
  //landing to home screen or rendering any component inside home component
  public afterMainComponentsReady = new EventEmitter<any>();
  //this event is fired when session expired from server
  public sessionExpired = new EventEmitter<any>();
  //delegate to stop dashboard data pooling
  public stopDashboardPooling = new BehaviorSubject<any>(false);
  //this will be used to detetmine if dashboard is active on screen(true) or not (false)
  isDashboardActive: boolean = false;
  fourthLayerSubChilds: boolean;
  fileuploadData;
  inquirycmdhistory;

  public mainMenus = [];
  public impMenus = [];
  public mobMenus = [];
  /**
   * This class hold menus object and its fecilitator functions and event
   * @param v1Utiltity
   * @param Http
   * @param authServ
   * @param _utilityService
   * @param loaderSerivce
   * @param appInitService
   */
  constructor(private v1Utiltity: V1UtilitiesService, private Http: HttpClient, private authServ: AuthenticationService,
    private _utilityService: UtilityService, public loaderSerivce: LoaderService, private appInitService: AppInitService) {
    this.authServ.onMenuResponse.subscribe(x => {
      // console.log("Got Event From Menu Event Emitter)))))))", x)
      let mainMenuLine = this.v1Utiltity.parseResponse(x);
      let mainMenuVal = mainMenuLine.find(y => y.code == '110').value;
      if (mainMenuVal) {
        this.mainMenus = mainMenuVal.split("|");
        this.LoadMenus();
      }
    });
    this.authServ.onImpMenuResponse.subscribe(y => {
      // console.log('X-Environment', this.authServ.currentUserValue.designation)
      let mainImpLine = this.v1Utiltity.parseResponse(y);
      if (mainImpLine.find(x => x.code == '110')) {
        let mainImpVal = mainImpLine.find(x => x.code == '110').value;
        if (mainImpVal) {

          this.impMenus = mainImpVal.split("|");
          this.impMenus.shift();
          this.LoadMenus();
        }
      }


    });
    this.authServ.onMobMenuResponse.subscribe(z => {
      let mobImpLine = this.v1Utiltity.parseResponse(z);
      if (mobImpLine.find(x => x.code == '110')) {
        let mainMobVal = mobImpLine.find(x => x.code == '110').value
        this.mobMenus = mainMobVal.split("|");
        this.mobMenus.shift();

        this.mobMenus[0] = this.mobMenus[0].trim() + 'Mobile';
        this.LoadMenus();
      }
    });

    this.authServ.afterPageLoaded.subscribe(x => {

      this.afterMainComponentsReady.emit(x);
    });
  }
  dashboardcmd;
  /**
   * Main Menus Parsing function that parse menus and set in this class as binding object
   * @param res response in text format
   */
  LoadMenus() {

    let menuLine = [...this.mainMenus, ...this.impMenus, ...this.mobMenus]

    let v1Formated = this.V1FunctionForParsingMenusforParent(menuLine);

    if (!this.authServ.clientNameFromMenu || this.authServ.clientNameFromMenu.trim() == '') {
      this.authServ.clientNameFromMenu = (v1Formated.title + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace('Main Menu:', '').trim();
    } else {
      this.authServ.clientNameFromMenu = (v1Formated.title + '').trim().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace('Main Menu:', '').trim();
    }

    this.authServ.clinetNameChanged.emit(this.authServ.clientNameFromMenu);

    // console.log(v1Formated)
    v1Formated.sections.forEach((el, ind) => {

      let elemIndex = -1;
      if (el.name.includes('Dashboard')) {
        this.dashboardcmd = el.call
        elemIndex = ind;
        // console.log('this is new ceated dashboard', this.dashboardcmd);
      }

    });

    this.menu = this.ConstructMenuObject(v1Formated.sections);

    // console.log("From Menu Services\n", this.menu);

    // console.log('item to remove', this.menu.menus[this.menu.menus.length - 1].SubMenues[0]);
    this.menu.menus[this.menu.menus.length - 1].SubMenues.splice(0, 1);
    // console.log("new From Menu Services\n", this.menu);

  }
  /*This function iterate through each Master menu and loads their sub menues
  from server and at the end gives all menues with their sub menues*/
  LoadSubMenus(subMenuList: clsSubMenu[], pIndex) {

    // console.log("Iterate through Menue Index:", pIndex);
    return new Observable<any>(pObserver => {
      // console.log("Created Of Block for Index:", pIndex);
      if (pIndex > 0 && pIndex < 3) {
        from(subMenuList).pipe(
          concatMap((subMen, cIndex) =>
            new Observable<any>(observer => {
              // console.log("Iterate through SubMenue Index in inner logic:", pIndex);
              this.authServ.gemxPost({
                gconn: this.authServ.gConn,
                gcmd: '[MENU]' + subMen.cmd.value
              }).subscribe(z => {
                let res = this.v1Utiltity.parseResponse(z);
                let nextReturnComand = res.some(co => co.code == '104') ? '[RAW][ESC]' : '[MENU][ESC]';
                this.authServ.gemxPost({
                  gconn: this.authServ.gConn,
                  gcmd: nextReturnComand
                }).subscribe(a => {
                  observer.next({
                    subMen,
                    response: z,
                    pIndex,
                    cIndex
                  });
                  observer.complete();
                  if (subMenuList.length - 1 == cIndex) {
                    pObserver.complete();
                  }
                })
              })
            })
          )
        ).subscribe(r => {
          let resLines = this.v1Utiltity.parseResponse(r.response);
          if (resLines.some(x => x.code == '110')) {
            let v1Formated = this.V1FunctionForParsingMenus(resLines.find(x => x.code == '110').value);
            //This needs to be change with new Function commenting out this function call becuase it has remoed.
            //this.constructSubMenuObjOld(v1Formated.sections,r.pIndex,r.cIndex);
            // console.log("Nested Sub Menues:", this.menu);
          } else if (resLines.some(x => x.code == '104')) {

          } else {

          }

          // console.log("on RXJS Hit:", r);
        });
      } else {
        return pObserver.complete();
      }
    });
  }
  /**
   * This method generate URL route param from menu name
   * @param name  menu name
   * @returns route param
   */
  private createPathUrlFromName(name: string) {

    let tempFragment;

    if (name.indexOf('-') !== -1) {
      let frag = name.split('-');
      let num = +(frag[0] + '').trim();
      if (isNaN(num)) {
        tempFragment = name.trim().replace(/#|\/|\s/g, '-');
      } else {
        tempFragment = name.slice(name.indexOf('-') + 1).trim().replace(/#|\/|\s/g, '-')
      }

    } else {
      tempFragment = name.trim().replace(/#|\/|\s/g, '-');
    }

    return tempFragment.trim().endsWith('-') ? tempFragment.slice(0, -1) : tempFragment;

  }
  /**
   * This method is to check if route contain menu name
   * @param urlName route param
   * @param menuTitle menu text
   * @returns true/false
   */
  private checkURLFragmaentInTitle(urlName: string, menuTitle: String) {

    if (urlName.includes('%28')) {
      urlName = urlName.replace(/%28/g, '(').replace(/%29/g, ')');
    }

    let nameFrag = urlName.split('-');
    let nameInLowCase = menuTitle.trim().toLocaleLowerCase();
    let allFragExist: boolean = true;
    nameFrag.forEach(n => {
      if (!nameInLowCase.includes(n)) {
        allFragExist = false;
      }
    });
    return allFragExist;
  }
  /**
   * This method remove numbers from text , in old v1 most lables prefix with numbers for keyboard interaction this method removes those number s
   * @param text text
   * @returns text without number
   */
  removeNumberFromText(text) {
    if (text.indexOf(' - ') === -1) {
      return text;
    } else {
      let frag = text.split('-');
      let num = +(frag[0] + '').trim();
      if (isNaN(num)) {
        return text;
      } else {
        return text.slice(text.indexOf('-') + 1).trim()
      }

    }
  }
  /**
   * This function generate array of commands from starting point to ending point of active menus.
   * @returns array of menu commands
   */
  getForwardNavigationPath() {
    let navigationArray = [];
    this.menu.menus.forEach(element => {
      element.SubMenues.forEach(elementSubMenu => {
        if (elementSubMenu.isActive == true) {
          navigationArray.push({ type: 'request', cmd: '[MENU]' + elementSubMenu.cmd.value, title: elementSubMenu.title, nameToUrl: this.createPathUrlFromName(elementSubMenu.title) });
          elementSubMenu.childs.forEach(SubMenuChild => {
            // console.log('SubMenuChild', SubMenuChild)
            if (SubMenuChild.isActive) {
              if (SubMenuChild.isGridBasedMenu) {
                SubMenuChild.childs.forEach(child => {
                  if (child.isActive) {
                    if (child.title == "All inquiries") {
                      navigationArray.push({ type: 'request', cmd: SubMenuChild.cmd.value, title: SubMenuChild.title, nameToUrl: this.createPathUrlFromName(SubMenuChild.title) });
                      return navigationArray;
                    }
                    else {
                      navigationArray.push({ type: 'request', cmd: SubMenuChild.cmd.value, title: SubMenuChild.title, nameToUrl: this.createPathUrlFromName(SubMenuChild.title) });
                      navigationArray.push({ type: 'request', cmd: child.cmd.value, title: child.title, nameToUrl: this.createPathUrlFromName(child.title) });
                      child.childs.forEach(lastChild => {
                        if (lastChild.isActive)
                          navigationArray.push({ type: 'formRequest', cmd: lastChild.cmd.value, title: lastChild.title, nameToUrl: this.createPathUrlFromName(lastChild.title) });
                      })
                    }
                  }
                })
              }
              else {
                if (SubMenuChild.childs.length == 0 && SubMenuChild.cmd && SubMenuChild.cmd != null && SubMenuChild.cmd.value.length > 0) {
                  navigationArray.push({ type: 'formRequest', cmd: SubMenuChild.cmd.value, title: SubMenuChild.title, nameToUrl: this.createPathUrlFromName(SubMenuChild.title) });
                } else {
                  if (SubMenuChild.title.trim() !== '')
                    navigationArray.push({ type: '', cmd: '', title: SubMenuChild.title, nameToUrl: this.createPathUrlFromName(SubMenuChild.title) });
                  SubMenuChild.childs.forEach(child => {
                    if (child.isActive) {
                      if (child.childs.length == 0 && child.cmd && child.cmd != null && child.cmd.value.length > 0) {
                        navigationArray.push({ type: 'formRequest', cmd: child.cmd.value, title: child.title, nameToUrl: this.createPathUrlFromName(child.title) });
                      }
                      else {
                        if (child.title.trim() !== '')
                          navigationArray.push({ type: '', cmd: '', title: child.title, nameToUrl: this.createPathUrlFromName(child.title) });
                      }
                      child.childs.forEach(lastChild => {
                        if (lastChild.isActive) {
                          if (lastChild.childs.length == 0 && lastChild.cmd && lastChild.cmd != null && lastChild.cmd.value.length > 0)
                            navigationArray.push({ type: 'formRequest', cmd: lastChild.cmd.value, title: lastChild.title, nameToUrl: this.createPathUrlFromName(lastChild.title) });
                          else {
                            navigationArray.push({ type: 'request', cmd: '', title: lastChild.title, nameToUrl: this.createPathUrlFromName(lastChild.title) });
                            lastChild.childs.forEach(fourthChild => {
                              if (fourthChild.isActive) {
                                navigationArray.push({ type: 'formRequest', cmd: fourthChild.cmd.value, title: fourthChild.title, nameToUrl: this.createPathUrlFromName(fourthChild.title) })

                              }
                            })
                          }
                        }
                      })
                    }
                  });
                }
              }
            }
          });
        }
      });
    });
    return navigationArray;
  }

  /**
   * This function executes all commands on server in array in sequence.
   * @param cmds array of commands
   * @returns last command response
   */
  doNavigation: ([]) => Observable<any> = (cmds: string[]) => {

    return new Observable<any>(observer => {
      if (cmds.length > 0) {
        let source = from(cmds).pipe(
          concatMap((cmd, index) => {
            return new Observable<any>(observer => {
              this.authServ.gemxPost({
                gconn: this.authServ.gConn,
                gcmd: cmd
              }).subscribe(x => {
                if (index == cmds.length - 1) {
                  observer.next(x);
                } else {
                  observer.next('Navigating');
                }
                observer.complete();
              })
            });
          })
        ).subscribe(x => {
          // console.log("Back Navigation Process:", x);
          if (x != 'Navigating') {
            observer.next(x);
            observer.complete();
          }
        });
      } else {
        observer.next();
        observer.complete();
      }
    });

  }

  /**
   * This function is for fetching and parsing Submenues
   * @param cmds
   * @param obj
   * @param menuResponseObj
   * @returns
   */
  getSubMenues: ([], clsSubMenu, menuResponseObj?) => Observable<any> = (cmds: string[], obj: clsSubMenu, menuResponseObj) => {


    if (obj.childs.length > 0 && cmds.length > 0) {
      this.fourthLayerSubChilds = true;
    }
    return new Observable<any>(observer => {
      this.doNavigation(cmds).subscribe(r => {

        // console.log(r);
        let resLines;
        if (!r && menuResponseObj) {
          resLines = menuResponseObj;
        } else {
          resLines = this.v1Utiltity.parseResponse(r);
        }
        if (resLines.some(x => x.code == '110') && resLines.some(x => x.code == '110' && x.value.substring(0, 1) == '1') && !resLines.some(x => x.code == '117')) {
          let v1Formated = this.V1FunctionForParsingMenus(resLines.find(x => x.code == '110').value);
          //console.log("Formated V1",JSON.stringify(v1Formated.sections));


          let temSubObj = this.constructSubMenuObj(v1Formated.sections, obj);
          //console.log("After Sub Menu Loaded",this.menu ,JSON.stringify(temSubObj));
          observer.next(temSubObj);
          observer.complete();
        } else if (resLines.some(x => x.code == '104')) {
          //Inventory Menues
          let gridBaseMenu = this.v1Utiltity.ParseGrid(resLines.find(x => x.code == '104').value);
          // console.log("Grid Base Menu", gridBaseMenu);
          if (gridBaseMenu.headers.length == 1) {
            //This is One Selectable menu Options in grid format
            let menuObj = gridBaseMenu.data.map((x, i) => {
              return {
                name: x[1],
                call: '[FS]' + x[0]
              };
            });


            let temSubObj = this.constructSubMenuObj([menuObj], obj, true);
            observer.next(temSubObj);
            observer.complete();
          } else {

            observer.next({ type: 'WorkArea', responseLines: resLines, inBetweenMenuScreen: false });
            observer.complete();
          }
        } else if (resLines.some(x => x.code == '117')) {
          this.v1Utiltity.request_url_handler(resLines.find(x => x.code == '117').value.substring(1), this.authServ.currentUserValue.designation, this.authServ.currentUserValue.masterUser);
          //Old One Created By Hamza specifically for AD-hoc Reports
          //this._utilityService.navigatetoAddhoc(resLines.find(x=>x.code=='117').value.substring(1));
        } else if (resLines.some(x => x.code == '003')) {
          let obj = resLines.find(x => x.code == '003').value.split('|');
          let temObj = {
            _title: obj[2],
            body: obj[3],
            buttons: []
          }
          temObj.buttons.push({ text: obj[4], cmd: '0', lowercaseText: 'ok', isSessionExpired: true });
          temObj.buttons.push({ text: 'Reload Page', cmd: '0', lowercaseText: 'reload page', isSessionExpired: true })
          this.sessionExpired.emit(temObj);
        } else if (resLines.some(x => x.code == '109')) {
          let tempItem = resLines.find(x => x.code == '109').value.split('|');
          let temObj = {
            _title: tempItem[2],
            body: tempItem[3],
            buttons: []
          }
          if (tempItem[0] == '0') {//Standard MSg Box
            temObj.buttons.push({ text: tempItem[4], cmd: '0', lowercaseText: (tempItem[4] + '').toLocaleLowerCase() })
          } else if (tempItem[0] == '1') { //Multiple button msg box
            for (let b = 4; b < tempItem.length; b++) {
              temObj.buttons.push({ text: tempItem[b], cmd: tempItem[0] + '' + tempItem[1] + ';' + tempItem[b], lowercaseText: (tempItem[b] + '').toLocaleLowerCase() })
            }
          }
          this.sessionExpired.emit(temObj);
        } else if (resLines.some(x => x.code == '100') && !(resLines.some(x => x.code == '121'))) {
          //Between menu loading found Workarea / probably for user password confirmation
          let tempScreenObj: ScreenRenderer = new ScreenRenderer(this.v1Utiltity);
          tempScreenObj._ParseScreen(resLines, true);
          if (tempScreenObj._controls.length == 1 && (tempScreenObj._title + '').toLowerCase().includes('password')
            && (tempScreenObj._controls[0].elements.length > 0 && (tempScreenObj._controls[0].elements[0].cValue + '').toLowerCase().includes('password'))) {

            observer.next({ type: 'WorkArea', responseLines: resLines, inBetweenMenuScreen: true });
            observer.complete();
          } else {
            //Password Reset Screen

            observer.next({ type: 'WorkArea', responseLines: resLines, inBetweenMenuScreen: false });
            observer.complete();
          }

        } else if (resLines.some(x => x.code == '121')) {

          let val = resLines.find(x => x.code == '121').value;
          this.fileuploadData = val;
          observer.next({ type: 'WorkArea', responseLines: resLines, inBetweenMenuScreen: false });
          observer.complete();
        }
      });
      /*setTimeout(() => {
        console.log("Obj",obj)
      let v1Formated=JSON.parse('[[{"name":"ARN Processing","call":null},{"name":"101 - ARN Entry","call":"0000"},{"name":"102 - ARN Edit","call":"0001"},{"name":"103 - ARN Void/Delete","call":"0002"},{"name":"104 - ARN Print","call":"0003"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":"1-Step Processing","call":null},{"name":"111 - Receipt Entry/Edit","call":"0100"},{"name":" ","call":"****"},{"name":"Billing","call":null},{"name":"114 - ARN Billing","call":"0200"},{"name":"115 - AUTO Bill Codes","call":"0201"}],[{"name":"Receipt Processing","call":null},{"name":"201 - Receipt Info Entry","call":"0300"},{"name":"202 - Put Away Entry/Edit","call":"0301"},{"name":" ","call":"****"},{"name":"204 - Receipt Print","call":"0303"},{"name":"205 - Receipt Label Print","call":"0304"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":"211 - View Rcpt Exceptions","call":"0310"},{"name":"212 - FINAL RECEIPT POST","call":"0311"},{"name":" ","call":"****"},{"name":" ","call":"****"},{"name":"215 - Deliv Info Display","call":"0314"}],[{"name":"Open Inquiries","call":null},{"name":"301 - by ARN Number","call":"0400"},{"name":"302 - by Date Expected","call":"0401"},{"name":"303 - by Supplier Name","call":"0402"},{"name":"304 - by Product Code","call":"0403"},{"name":"305 - by Primary Ref#","call":"0404"},{"name":"306 - by Secondary Ref#","call":"0405"},{"name":" ","call":"****"},{"name":"History Inquiries","call":null},{"name":"309 - by ARN Number","call":"0500"},{"name":"310 - by Receiver No.","call":"0501"},{"name":"311 - by Date Received","call":"0502"},{"name":"312 - by Supplier Name","call":"0503"},{"name":"313 - by Product Code","call":"0504"},{"name":"314 - by Primary Ref#","call":"0505"},{"name":"315 - by Secondary Ref#","call":"0506"}]]');
      let temSubObj=this.constructSubMenuObj(v1Formated,obj);
      //console.log("After Sub Menu Loaded",this.menu ,JSON.stringify(temSubObj));
      observer.next(temSubObj);
      observer.complete();
      }, 1000); */
    });

  }
  /*
  This function called from <v1-ui-ux> component where this function is called on page load and when requested from menu
  Where this function calles subMenu on server and return  child menu request with command that is then fetched in <v1-ui-ux>
  component and called the form there.
  Also this function is called from customized UI-UX where SubMenu child is only requested to server
  */
  _SubMenuRequestOnPageLoad(URL_Fragment: any[], fromUrl: boolean) {

    return new Observable<any>(observer => {

      let titleParent = URL_Fragment[0];

      let mobCheck = URL_Fragment[0].split('-');

      if (mobCheck[0] == 'mobile') {

        titleParent = URL_Fragment[0].substr(URL_Fragment[0].indexOf('-') + 1);
        for (let i = 0; i < this.menu.menus.length; i++) {
          if (mobCheck[0] == this.menu.menus[i].title.toLowerCase()) {
            for (let j = 0; j < this.menu.menus[i].SubMenues.length; j++) {

              if (fromUrl) {

                if (this.checkURLFragmaentInTitle(titleParent, this.menu.menus[i].SubMenues[j].title)) {
                  this.menu.resetAllMenuesonSubChildLoad(i, j);
                  //this._utilityService.isAnyModuleRendered()?this._utilityService.navigateToHome():false;
                  this.menu.menus[i].loadSubMenus(j, ['[MENU]' + this.menu.menus[i].SubMenues[j].cmd.value]).subscribe((y) => {
                    //If there is direct work area right after loading any main menu item

                    if (y && y.type && y.type == 'WorkArea') {
                      if (!y.inBetweenMenuScreen) {
                        observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                        observer.complete();
                      }
                    } else {
                      this.menu.menus[i].SubMenues.forEach((elementSubMenu, activeIndex) => {
                        if (elementSubMenu.isActive == true) {
                          let titleChild = URL_Fragment[1];
                          elementSubMenu.childs.forEach((SubMenuChild, subMenuIndex) => {
                            if (SubMenuChild.isGridBasedMenu) {
                              if (this.checkURLFragmaentInTitle(titleChild, SubMenuChild.title)) {
                                elementSubMenu.setAsActive(subMenuIndex);
                                elementSubMenu.setAsActiveArrowToggle(subMenuIndex)
                                this.menu.menus[i].loadSubMenus(activeIndex, [elementSubMenu.childs[subMenuIndex].cmd.value], true, subMenuIndex).subscribe((t) => {

                                  if (t && t.type && t.type == 'WorkArea') {
                                    if (!t.inBetweenMenuScreen) {
                                      observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                                      observer.complete();
                                    }
                                  } else
                                    //If there is thertury Menu in URL fragment
                                    if (URL_Fragment.length > 2) {
                                      let terturyHeader = URL_Fragment[2];
                                      let tertMenu = this.menu.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined))
                                        .SubMenues.find(y1 => y1.isActive == true).childs.find(z => z.isActive == true)
                                        .childs.forEach((tertMen, _tertMenIndex) => {
                                          if (this.checkURLFragmaentInTitle(terturyHeader, tertMen.title)) {
                                            let terturyChild = URL_Fragment[3];
                                            tertMen.childs.forEach((hoverMenu, hoverMenuIndex) => {
                                              if (this.checkURLFragmaentInTitle(terturyChild, hoverMenu.title)) {
                                                tertMen.setAsActive(hoverMenuIndex);
                                                tertMen.setAsActiveArrowToggle(hoverMenuIndex)
                                                observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + hoverMenu.cmd.value] });
                                                observer.complete();
                                              }
                                            })
                                          }
                                        })
                                    }
                                });
                              }
                            } else {

                              if (this.checkURLFragmaentInTitle(titleChild, SubMenuChild.title)) {
                                if (SubMenuChild.childs.length == 0 && SubMenuChild.cmd && SubMenuChild.cmd != null && SubMenuChild.cmd.value.length > 0) {

                                  this.menu.menus[i].resetChildMenusActivate(j);
                                  this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActive();
                                  this.menu.menus[i].loadSubMenus(activeIndex, ['[MENU]' + SubMenuChild.cmd.value], true, subMenuIndex).subscribe((t) => {

                                    if (t && t.type && t.type == 'WorkArea') {
                                      if (!t.inBetweenMenuScreen) {
                                        observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                                        observer.complete();
                                      }
                                    } else {
                                      let terturyChild = URL_Fragment[2];
                                      SubMenuChild.childs.forEach((child, childIndex) => {
                                        if (this.checkURLFragmaentInTitle(terturyChild, child.title)) {
                                          this.menu.menus[i].resetChildMenusActivate(j);
                                          this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActive(childIndex);
                                          this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActiveArrowToggle(childIndex);

                                          observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + child.cmd.value] });
                                          observer.complete();
                                        }
                                      })

                                    }
                                  })


                                } else {
                                  let terturyChild = URL_Fragment[2];


                                  SubMenuChild.childs.forEach((child, childIndex) => {
                                    if (this.checkURLFragmaentInTitle(terturyChild, child.title)) {
                                      this.menu.menus[i].resetChildMenusActivate(j);
                                      this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActive(childIndex);
                                      this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActiveArrowToggle(childIndex);
                                      this.menu.menus[i].loadSubMenus(activeIndex, ['[MENU]' + child.cmd.value], true, subMenuIndex).subscribe((t) => {
                                        if (t && t.type && t.type == 'WorkArea') {
                                          if (!t.inBetweenMenuScreen) {
                                            observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                                            observer.complete();
                                          }
                                        } else {
                                          if (child.childs.length > 0) {
                                            child.childs.forEach((fourthchild, fourthchildIndex) => {
                                              let Child4_1 = URL_Fragment[3];
                                              if (this.checkURLFragmaentInTitle(Child4_1, fourthchild.title)) {
                                                this.menu.menus[i].resetChildMenusActivate(j);
                                                this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].childs[childIndex].setAsActive(fourthchildIndex);

                                                this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].childs[childIndex].setAsActiveArrowToggle(fourthchildIndex);
                                                if (fourthchild.cmd.value) {
                                                  observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + fourthchild.cmd.value] });
                                                  observer.complete();
                                                } else {
                                                  if (fourthchild.childs.length > 0) {
                                                    fourthchild.childs.forEach((fourth2child, fourth2childIndex) => {
                                                      this.menu.menus[i].resetChildMenusActivate(j);
                                                      this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].childs[childIndex].childs[fourthchildIndex].setAsActive(fourth2childIndex);

                                                      this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].childs[childIndex].childs[fourthchildIndex].setAsActiveArrowToggle(fourth2childIndex);
                                                      let Child4_2 = URL_Fragment[4];
                                                      if (this.checkURLFragmaentInTitle(Child4_2, fourth2child.title)) {
                                                        if (fourth2child.cmd.value) {
                                                          observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + fourth2child.cmd.value] });
                                                          observer.complete();
                                                        }
                                                      }
                                                    })
                                                  }

                                                }
                                              }

                                            })
                                          }

                                        }
                                      })
                                      // observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + child.cmd.value] });
                                      // observer.complete();
                                    }
                                  });
                                }
                              }
                            }
                          });
                        }
                      });
                    }

                  });
                }
              } else {

                if ((this.removeNumberFromText((this.menu.menus[i].SubMenues[j].title + '')).trim().toLowerCase()) == (titleParent.trim().toLowerCase())) {
                  this.menu.resetAllMenuesonSubChildLoad(i, j);
                  this.menu.menus[i].loadSubMenus(j, ['[MENU]' + this.menu.menus[i].SubMenues[j].cmd.value]).subscribe((_y) => {
                    observer.next();
                    observer.complete();

                  });
                }
              }

            }
          }
        }
      } else {

        for (let i = 0; i < this.menu.menus.length; i++) {
          for (let j = 0; j < this.menu.menus[i].SubMenues.length; j++) {

            if (fromUrl) {

              if (this.checkURLFragmaentInTitle(titleParent, this.menu.menus[i].SubMenues[j].title)) {
                this.menu.resetAllMenuesonSubChildLoad(i, j);
                //this._utilityService.isAnyModuleRendered()?this._utilityService.navigateToHome():false;
                this.menu.menus[i].loadSubMenus(j, ['[MENU]' + this.menu.menus[i].SubMenues[j].cmd.value]).subscribe((y) => {

                  //If there is direct work area right after loading any main menu item

                  if (y && y.type && y.type == 'WorkArea') {
                    if (!y.inBetweenMenuScreen) {
                      observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                      observer.complete();
                    }
                  } else {
                    this.menu.menus[i].SubMenues.forEach((elementSubMenu, activeIndex) => {
                      if (elementSubMenu.isActive == true) {
                        let titleChild = URL_Fragment[1];
                        elementSubMenu.childs.forEach((SubMenuChild, subMenuIndex) => {
                          if (SubMenuChild.isGridBasedMenu) {
                            if (this.checkURLFragmaentInTitle(titleChild, SubMenuChild.title)) {
                              elementSubMenu.setAsActive(subMenuIndex);
                              elementSubMenu.setAsActiveArrowToggle(subMenuIndex);
                              this.menu.menus[i].loadSubMenus(activeIndex, [elementSubMenu.childs[subMenuIndex].cmd.value], true, subMenuIndex).subscribe((t) => {

                                if (t && t.type && t.type == 'WorkArea') {
                                  if (!t.inBetweenMenuScreen) {
                                    observer.next({ nextRequestType: 'WorkArea', cmds: [] });
                                    observer.complete();
                                  }
                                } else
                                  //If there is thertury Menu in URL fragment
                                  if (URL_Fragment.length > 2) {
                                    let terturyHeader = URL_Fragment[2];
                                    let tertMenu = this.menu.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined))
                                      .SubMenues.find(y => y.isActive == true).childs.find(z => z.isActive == true)
                                      .childs.forEach((tertMen, tertMenIndex) => {
                                        if (this.checkURLFragmaentInTitle(terturyHeader, tertMen.title)) {
                                          let terturyChild = URL_Fragment[3];
                                          tertMen.childs.forEach((hoverMenu, hoverMenuIndex) => {
                                            if (this.checkURLFragmaentInTitle(terturyChild, hoverMenu.title)) {
                                              tertMen.setAsActive(hoverMenuIndex);
                                              tertMen.setAsActiveArrowToggle(hoverMenuIndex);
                                              observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + hoverMenu.cmd.value] });
                                              observer.complete();
                                            }
                                          })
                                        }
                                      })
                                  }
                              });
                            }
                          } else {
                            if (this.checkURLFragmaentInTitle(titleChild, SubMenuChild.title)) {
                              if (SubMenuChild.childs.length == 0 && SubMenuChild.cmd && SubMenuChild.cmd != null && SubMenuChild.cmd.value.length > 0) {
                                this.menu.menus[i].resetChildMenusActivate(j);
                                this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActive();
                                this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActiveArrowToggle();
                                observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + SubMenuChild.cmd.value] });
                                observer.complete();
                              } else {
                                let terturyChild = URL_Fragment[2];
                                SubMenuChild.childs.forEach((child, childIndex) => {
                                  if (this.checkURLFragmaentInTitle(terturyChild, child.title)) {
                                    this.menu.menus[i].resetChildMenusActivate(j);
                                    this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActive(childIndex);
                                    this.menu.menus[i].SubMenues[activeIndex].childs[subMenuIndex].setAsActiveArrowToggle(childIndex);
                                    observer.next({ nextRequestType: 'FormRequest', cmds: ['[MENU]' + child.cmd.value] });
                                    observer.complete();
                                  }
                                });
                              }
                            }
                          }
                        });
                      }
                    });
                  }

                });
              }
            } else {

              titleParent = titleParent;
              if ((this.removeNumberFromText((this.menu.menus[i].SubMenues[j].title + '')).trim().toLowerCase()) == (titleParent.trim().toLowerCase())) {
                this.menu.resetAllMenuesonSubChildLoad(i, j);
                //this._utilityService.isAnyModuleRendered()?this._utilityService.navigateToHome():false;
                this.menu.menus[i].loadSubMenus(j, ['[MENU]' + this.menu.menus[i].SubMenues[j].cmd.value]).subscribe((y) => {

                  observer.next();
                  observer.complete();

                });
              }
            }

          }
        }
      }


    });

  }


  V1FunctionForParsingMenus(data) {
    // split the data
    data = data.split("|");

    // parse the intro data
    var menudata: any = {};
    menudata.is_back_button = (data[0].charAt(0) == '0' ? false : true);
    menudata.length = data[0][1];
    menudata.sections = [];
    menudata.title = data[0].substr(2);
    menudata.superpositions = [];

    if (menudata.title.charAt(0) == '0' ||
      menudata.title.charAt(0) == '1') {
      menudata.title = menudata.title.substr(1);
    }

    var supercolumns = {};
    var sectionitems = [];
    for (var i = 1; i < data.length; i++) {
      var match = null;
      if (match = data[i].match(/([^~]+)~([^~]+)~(.*)/)) {

        var section = {
          'row': match[1],
          'column': match[2],
          'name': match[3],
          'length': data[i + 1],
          items: []
        };

        // first add our section
        menudata.sections.push(section);

        // now figure out the supercolumn position
        if (!supercolumns[section.column]) {
          supercolumns[section.column] = {};
        }
        supercolumns[section.column][section.row] = menudata.sections.length - 1;

        // now add one itereation to skip past the section item count
        i++;

      } else {
        var sectionitem = data[i].split("~");
        sectionitems.push({
          'call': sectionitem[0],
          'name': sectionitem[1]
        });
      }
    }

    // Note: Parsing the data presents a weird logic problem because js doesn't
    // post values until after the loop
    for (var i = 0; i < menudata.sections.length; i++) {
      for (var j = 0; j < menudata.sections[i].length; j++) {
        menudata.sections[i].items.push(sectionitems.shift());
      }
    }

    // Now build cols construct
    var cols = {};
    for (var i = 0; i < menudata.sections.length; i++) {
      var row = parseInt(menudata.sections[i].row);
      var col = parseInt(menudata.sections[i].column);
      var items = menudata.sections[i].items;

      if (typeof (cols[col]) == 'undefined') {
        cols[col] = [];
      }
      cols[col][row] = {};
      cols[col][row].name = menudata.sections[i].name;
      cols[col][row].call = null;

      for (var j = 0; j < items.length; j++) {
        var count = row + j + 1
        cols[col][count] = {};
        cols[col][count].name = items[j].name;
        cols[col][count].call = items[j].call;
      }
    }

    // Get min and max range
    let minCol: any = 999;
    let maxCol: any = 0;
    for (let key in cols) {
      for (let col in cols[key]) {
        minCol = parseInt(col < minCol ? +col : minCol);
        maxCol = parseInt(col > maxCol ? col : maxCol);
      }
    }

    // Get array keys
    var keys = {};
    var i = 0;
    for (var key in cols) {
      keys[key] = i;
      i++;
    }

    // So much fun . . . get array keys
    var raw = [];
    var h = 0;
    for (var key in cols) {
      raw[h] = [];
      for (let i = minCol; i <= maxCol; i++) {
        if (typeof (cols[key][i]) != 'undefined') {
          raw[h][i - minCol] = cols[key][i];
        } else {
          raw[h][i - minCol] = { name: ' ', call: '****' };
        }
      }

      h++;
    }
    menudata.sections = raw;
    // Write to our object
    //$.gemxweb.menu.classic.data = menudata;

    // Also return the data
    // console.log("Menu constructed", menudata);
    return menudata;
  }

  /**
   * This function parse test response of menu into json in v1-format
   * @param data text data
   * @returns v1-formated menu object
   */
  V1FunctionForParsingMenusforParent(data, parentMenu?) {

    var menudata: any = {};
    menudata.is_back_button = (data[0].charAt(0) == '0' ? false : true);
    menudata.length = data[0][1];
    menudata.sections = [];
    menudata.title = data[0].substr(2);
    menudata.superpositions = [];

    if (menudata.title.charAt(0) == '0' ||
      menudata.title.charAt(0) == '1') {
      menudata.title = menudata.title.substr(1);
    }

    var supercolumns = {};
    var sectionitems = [];
    for (var i = 1; i < data.length; i++) {
      var match = null;
      if (match = data[i].match(/([^~]+)~([^~]+)~(.*)/)) {

        var section = {
          'row': match[1],
          'column': match[2],
          'name': match[3],
          'length': data[i + 1],
          items: []
        };

        // first add our section
        menudata.sections.push(section);

        // now figure out the supercolumn position
        if (!supercolumns[section.column]) {
          supercolumns[section.column] = {};
        }
        supercolumns[section.column][section.row] = menudata.sections.length - 1;

        // now add one itereation to skip past the section item count
        i++;

      } else {
        var sectionitem = data[i].split("~");
        sectionitems.push({
          'call': sectionitem[0],
          'name': sectionitem[1]
        });
      }
    }

    // console.log(supercolumns, sectionitem)

    // Note: Parsing the data presents a weird logic problem because js doesn't
    // post values until after the loop
    for (var i = 0; i < menudata.sections.length; i++) {
      for (var j = 0; j < menudata.sections[i].length; j++) {
        menudata.sections[i].items.push(sectionitems.shift());
      }
    }


    // console.log(menudata)
    //  Now build cols construct

    let cols = [];

    for (var i = 0; i < menudata.sections.length; i++) {

      var items = menudata.sections[i].items;
      let colsobj = { name: menudata.sections[i].name, call: null };

      cols.push(colsobj);

      for (var j = 0; j < items.length; j++) {
        let colsobj = { name: items[j].name, call: items[j].call };

        cols.push(colsobj)
      }

      // console.log(cols)

    }

    // console.log(cols)
    // Get min and max range
    // let minCol: any = 999;
    // let maxCol: any = 0;
    // for (let key in cols) {
    //   for (let col in cols[key]) {
    //     minCol = parseInt(col < minCol ? +col : minCol);
    //     maxCol = parseInt(col > maxCol ? col : maxCol);
    //   }
    // }

    // // Get array keys
    // var keys = {};
    // var i = 0;
    // for (var key in cols) {
    //   keys[key] = i;
    //   i++;
    // }

    // // So much fun . . . get array keys
    // var raw = [];
    // var h = 0;
    // for (var key in cols) {
    //   raw[h] = [];
    //   for (let i = minCol; i <= maxCol; i++) {
    //     if (typeof (cols[key][i]) != 'undefined') {
    //       raw[h][i - minCol] = cols[key][i];
    //     } else {
    //       raw[h][i - minCol] = { name: ' ', call: '****' };
    //     }
    //   }

    //   h++;
    // }

    // console.log(raw)
    menudata.sections = cols;
    // // Write to our object
    // //$.gemxweb.menu.classic.data = menudata;

    // // Also return the data
    // console.log("Menu constructed", menudata);
    return menudata;
  }
  /**
   * This Function construct main menu object from v1-formated menu
   * @param menuSection v1-formated menu object
   * @returns
   */
  ConstructMenuObject(menuSection) {

    let tempMenOb: clsMenu = new clsMenu();
    //let v1Object=[];
    for (let i = 0; i < menuSection.length; i++) {
      let isStart = false;
      //    for (let j = 0; j < menuSection[i].length; j++) {

      let obj = menuSection[i];
      if (obj.call == null) { isStart = true; }
      if (isStart == true) {
        let menuItem: clsMenuItem = new clsMenuItem(this.getSubMenues, this.appInitService.menu_mapping_config);
        menuItem.title = this.v1Utiltity.decodeHtmlEntities(obj.name);
        menuItem.iconUrl;
        menuItem.tooltip = menuItem.title;
        menuItem.isWeb = true;
        menuItem.hasPanel = false;
        menuItem.isCap = false;
        tempMenOb.menus.push(menuItem);
        isStart = false;
      }
      else if (obj.call !== "****") {
        let tempSubMe: clsSubMenu = new clsSubMenu();
        tempSubMe.title = this.v1Utiltity.decodeHtmlEntities(obj.name);
        tempSubMe.tooltip = tempSubMe.title;
        tempSubMe.cmd = new clsCMD(obj.call, obj.call);
        tempMenOb.menus[tempMenOb.menus.length - 1].SubMenues.push(tempSubMe);
        tempMenOb.menus[tempMenOb.menus.length - 1].hasPanel = true;
      }
      //     }
    }

    tempMenOb.setIcons();
    return tempMenOb;
  }
  /**
   * This method creates Sub Menu object from v1-formated object into v3-format
   * @param data v1-format menu object
   * @param menuObj refrence of parent menu
   * @param isGridBaseMenu either grid menu or simple menu
   * @returns
   */
  constructSubMenuObj(data: Array<any>[], menuObj: clsSubMenu, isGridBaseMenu?: boolean) {


    //let menuObj:clsSubMenu=new clsSubMenu(this.getSubMenues);
    for (let i = 0; i < data.length; i++) {
      let isStart = false;
      let activeChild = null;
      let innerActiveChild = -1;
      for (let j = 0; j < data[i].length; j++) {
        let obj = data[i][j];
        if (isGridBaseMenu) {

          //When Grid base menu
          let tempSubMe: clsSubMenu = new clsSubMenu();
          tempSubMe.title = this.removeNumberFromText(this.v1Utiltity.decodeHtmlEntities(obj.name));
          tempSubMe.tooltip = tempSubMe.title;
          tempSubMe.cmd = new clsCMD(obj.call, obj.call);
          tempSubMe.isGridBasedMenu = true;
          if (this.fourthLayerSubChilds && menuObj.childs.find(child => child.isActive)) {
            activeChild = menuObj.childs.findIndex(child => child.isActive);
            innerActiveChild = menuObj.childs[activeChild].childs.findIndex(child => child.isActive);
            if (innerActiveChild > -1) {
              menuObj.childs[activeChild].childs[innerActiveChild].childs.push(tempSubMe);
            }
            else {
              menuObj.childs[activeChild].childs.push(tempSubMe);
            }
          }
          else
            menuObj.childs.push(tempSubMe);
        } else {
          //When V1 format menu

          // if(obj.name.trim() != "" && obj.call != "****") {
          if (obj.call == null) { isStart = true; }
          if (isStart == true) {
            let tempSubMe: clsSubMenu = new clsSubMenu();
            tempSubMe.title = this.removeNumberFromText(this.v1Utiltity.decodeHtmlEntities(obj.name));
            tempSubMe.tooltip = tempSubMe.title;
            tempSubMe.cmd = new clsCMD(obj.call, obj.call);
            if (this.fourthLayerSubChilds && menuObj.childs.find(child => child.isActive)) {
              activeChild = menuObj.childs.findIndex(child => child.isActive);
              innerActiveChild = menuObj.childs[activeChild].childs.findIndex(child => child.isActive);
              if (innerActiveChild > -1) {
                menuObj.childs[activeChild].childs[innerActiveChild].childs.push(tempSubMe);
              }
              else {
                menuObj.childs[activeChild].childs.push(tempSubMe);
              }
            }
            else
              menuObj.childs.push(tempSubMe);
            isStart = false;
          }
          else if (obj.call !== "****") {
            let tempChild: clsSubMenu = new clsSubMenu();
            tempChild.title = this.removeNumberFromText(this.v1Utiltity.decodeHtmlEntities(obj.name));
            tempChild.tooltip = tempChild.title;
            tempChild.cmd = new clsCMD(obj.call, obj.call);
            if (this.fourthLayerSubChilds && menuObj.childs.find(child => child.isActive)) {
              activeChild = menuObj.childs.findIndex(child => child.isActive);
              innerActiveChild = menuObj.childs[activeChild].childs.findIndex(child => child.isActive);
              if (innerActiveChild > -1) {
                menuObj.childs[activeChild].childs[innerActiveChild].childs.push(tempChild);
              } else if (menuObj.childs[activeChild].childs[menuObj.childs[activeChild].childs.length - 1]) {
                menuObj.childs[activeChild].childs[menuObj.childs[activeChild].childs.length - 1].childs.push(tempChild);
              }
              else {
                menuObj.childs[activeChild].childs.push(tempChild);
              }
            }
            else
              menuObj.childs[menuObj.childs.length - 1].childs.push(tempChild);
          }
          // }
        }
      }
    }
    //Check if any header text is empty, then move all child to parent place
    for (let i = 0; i < menuObj.childs.length; i++) {
      if (menuObj.childs[i].title.trim() == '') {
        let child = menuObj.childs.splice(i, 1);
        menuObj.childs = menuObj.childs.concat(child[0].childs);
      }
    }
    return menuObj;
  }
  /**
   * Fetch all inquiries that are active and title as Opened Inquiries
   * @param indexNo
   * @returns
   */
  // getActivatedOpenEnqueriesMenu(indexNo){
  //   return this.menu.menus.find(x=>x.isActiveForChildVisibility==true)
  //   .SubMenues.find(x=>x.isActive==true)
  //   .childs[indexNo].childs.map(x=>{
  //     return {
  //       name:(x.title+'').slice(5),
  //       cmd:x.cmd.value
  //     }
  //   });
  // }
  /**
   * This method navigates back to menu section ,no matter use is standing on any state ,
   * @param loadedScreen last Response text
   * @returns wither successfully navigated or stuck somewhere
   */
  navigateToMenuSection(loadedScreen?, impMenu?, mobMenu?) {
    const matchMenu: (prev, next) => boolean = (prev, next) => {
      let splitedPrevRes: [] = prev.split('\n');
      let splitedNextRes: [] = next.split('\n');
      return splitedPrevRes.find(pr => (pr + '').startsWith('110')) === splitedNextRes.find(nr => (nr + '').startsWith('110'))
    }
    const responseParser: (res) => any = (res) => {
      return res === 'onMenuScreen' ? 'PASS' :
        (res === 'BackEndStuck' ? 'FAIL' :
          (res === 'BackEndStuckOnError' ? 'FailWithError' :
            (res === 'SessionExpired' ? res : 'FAIL')));
    }
    const iterativeReq: (isMenu, prevScreen, notNavigationToBack?: number) => Observable<any> = (isMenu: boolean, prevScreen: string, notNavigationToBack?: number) => {
      if (isMenu) {
        this.authServ.ConstructFunctionKeysList(prevScreen);
        return this.authServ.gemxPost({
          gconn: this.authServ.gConn,
          gcmd: '[MENU][ESC]'
        }).pipe(concatMap((res) => {
          if (matchMenu(res, prevScreen)) {
            this.authServ.ConstructFunctionKeysList(res);
            return new Observable<any>((ob) => { ob.next("onMenuScreen"); ob.complete(); });
          } else {
            return iterativeReq(true, res);
          }
        }));
      } else {
        return this.authServ.gemxPost({
          gconn: this.authServ.gConn,
          gcmd: '[RAW][ESC]'
        }).pipe(concatMap((res) => {
          if (res === prevScreen) {
            if (notNavigationToBack && notNavigationToBack > 2) {
              let resLines = this.v1Utiltity.parseResponse(res);
              if (resLines.find(f => f.code == '003')) {
                return new Observable<any>((ob) => { ob.next("SessionExpired"); ob.complete(); });
              } else {
                return new Observable<any>((ob) => { ob.next("BackEndStuck"); ob.complete(); });
              }

            } else {
              notNavigationToBack = (!notNavigationToBack) ? 1 : notNavigationToBack + 1;
              return iterativeReq(false, res, notNavigationToBack);
            }
          } else {
            let resLines = this.v1Utiltity.parseResponse(res);
            if (resLines.find(f => f.code == '110')) {
              return iterativeReq(true, res);
            }
            //there is a error msg box ,that why we  cannot go back automtically without user action, so that will
            else if (resLines.find(f => f.code == '109')) {
              return new Observable<any>((ob) => { ob.next("BackEndStuckOnError"); ob.complete(); });
            } else {
              return iterativeReq(false, res);
            }
          }
        }))
      }
    }
    return new Observable<any>(observer => {
      let resLinesLoadScreen = this.v1Utiltity.parseResponse(loadedScreen);

      resLinesLoadScreen.some(d => d.code == '121') ? loadedScreen = undefined : false;

      if (loadedScreen) {

        iterativeReq(resLinesLoadScreen.some(f => f.code == '110'), loadedScreen).subscribe(final => {

          if (impMenu) {

            this.authServ.gemxPost({
              gconn: this.authServ.gConn,
              gcmd: '[RAW]imp'
            }).subscribe(final2 => {
              observer.next(responseParser(final2));
              observer.complete();
            })
          }
          else if (mobMenu) {

            this.authServ.gemxPost({
              gconn: this.authServ.gConn,
              gcmd: '[RAW]mob'
            }).subscribe(final3 => {
              observer.next(responseParser(final3));
              observer.complete();
            })
          } else {


            observer.next(responseParser(final));
            observer.complete();
          }
        });
      } else {

        this.authServ.gemxPost({
          gconn: this.authServ.gConn,
          gcmd: '[RAW]'
        }).pipe(concatMap((currentScreen) => {
          let resLines = this.v1Utiltity.parseResponse(currentScreen);
          return iterativeReq(resLines.some(f => f.code == '110'), currentScreen);
        })).subscribe(final => {


          if (impMenu) {
            this.authServ.gemxPost({
              gconn: this.authServ.gConn,
              gcmd: '[RAW]imp'
            }).subscribe(final2 => {
              observer.next(responseParser(final2));
              observer.complete();
            })
          }
          else if (mobMenu) {
            this.authServ.gemxPost({
              gconn: this.authServ.gConn,
              gcmd: '[RAW]mob'
            }).subscribe(final3 => {
              observer.next(responseParser(final3));
              observer.complete();
            })
          } else {
            observer.next(responseParser(final));
            observer.complete();
          }
        });
      }
    });

  }
}
/**
 * Menus Schema
 */
export class clsMenu {
  //loaded menus
  menus: clsMenuItem[] = [];
  //to activete main menu panel
  panelActive;
  /**
   * Fetching All Inquiries menu options from menu object
   * @returns inquiries menus only
   */

  // STANDARDIZING INVENTORY MANAGEMENT SECOND LEVEL INQUIRIES
  getInquiriesMenuItems(level = 'upper') {
    let tempObj;
    if (level == 'lower') {
      try {
        tempObj = Object.assign([], this.menus.find(main => (
          main.SubMenues.find(firstChild => (firstChild.isActive == true)))
        ).SubMenues.find(secondChild => (secondChild.isActive == true))
          .childs.find(thirdChild => (thirdChild.isActive == true))
          .childs.filter(child => (child.title + '').includes('Inquiries') && child['isInqAll'] == undefined)
        );
      }
      catch {
        tempObj = []
      }
    }
    else {
      tempObj = Object.assign([],
        this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
          .childs.filter(x => ((x.title + '').includes('Inquiries') && x['isInqAll'] == undefined))
      );
    }
    return tempObj;
  }

  /**
   * This is to set the Active menu property setMenuStateForStateManagment('file maintenance','suppliers')
   * @param parentMenu parent menu tite
   * @param header header title of menu
   */
  setMenuStateForStateManagment(parentMenu, header?, onlySetACtivechildVisibilty?, menu_mapping_Obj?: menuMapping) {

    if (parentMenu == 'inventorymanagement') {
      let inq = this.menus.find(main => (
        main.SubMenues.find(firstChild => (firstChild.isActive == true)))
      ).SubMenues.find(secondChild => (secondChild.isActive == true))
        .childs.find(thirdChild => (thirdChild.isActive == true))
        .childs.filter(child => (child.title + '').includes('Inquiries'));
      if (inq.length > 0) {
        let opneInqIndex = inq.findIndex(z => z.title.trim().toLowerCase().match(/open.+inquiries/));
        if (opneInqIndex !== -1) {
          inq[opneInqIndex].setAsActive(0);
        } else {
          inq[0].setAsActive(0);
        }
      }
      let process = this.menus.find(x => (x.SubMenues.find(y => y.isActive == true))).SubMenues.find(y => y.isActive == true).childs.find(y => y.isActive).childs.find(y => y.isActive);
      if (!process.title.includes('Inquiries'))
        process.setAsDeActive();
      return
    }

    let activeItemIndex = this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.findIndex(y => y.isActive == true);
    if (activeItemIndex !== -1) {
      if (!onlySetACtivechildVisibilty) {
        this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).resetChildMenusActivate(activeItemIndex);
      }
      let panelIndex = this.menus.findIndex(x => (x.SubMenues.find(y => y.isActive == true) != undefined));
      if (panelIndex !== -1) {
        this.menus[panelIndex].isActiveForChildVisibility = true;
      }
    }

    if (parentMenu == 'orderShipment' || parentMenu == 'arnRecieptProcessing') {
      let orderMenuJsonObj = this.getModuleWiseMenusTitle(parentMenu, header, menu_mapping_Obj);
      let inq = this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
        .childs.filter(x => (x.title + '').includes('Inquiries'));
      if (inq.length > 0) {
        let opneInqIndex = inq.findIndex(z => z.title.trim().toLowerCase().match(/open.+inquiries/));
        if (opneInqIndex !== -1) {
          inq[opneInqIndex].setAsActive(0);
        } else {
          inq[0].setAsActive(0);
        }
      }


      let process = this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
        .childs.filter(x => (this.menus[0].removeNumberFromText(x.title + '')).trim().toLowerCase() == orderMenuJsonObj.menuHeaderTitle.trim().toLowerCase());
      if (process.length > 0) {
        process[0].applyActiveClass = true;
        if (process[0].childs.find(x => x.shouldRender == true)) {
          process[0].toggleChild = true;
        }
      }

      // console.log("Inqueries Menues", inq);
    } else {
      if (header && parentMenu != 'inventorymanagement') {
        let flMenMenuJsonObj = this.getModuleWiseMenusTitle(parentMenu, header, menu_mapping_Obj);
        let inq = this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
          .childs.filter(x => (this.menus[0].removeNumberFromText(x.title + '')).trim().toLowerCase() == flMenMenuJsonObj.menuHeaderTitle.trim().toLowerCase());
        if (parentMenu == 'file_maintenance') {
          let inqIndex = inq.length > 0 ? inq[0].childs.findIndex(x => (this.menus[0].removeNumberFromText(x.title + '')).trim().toLowerCase() == (flMenMenuJsonObj.values.find(d => d.key == 'inquiry')?.title).trim().toLowerCase()) : -1;
          // console.log("Suppliers Menues", inq, inqIndex);
          if (inqIndex > -1) {
            inq[0].setAsActive(inqIndex);
            inq[0].applyActiveClass = true;
          }
        } else if (parentMenu == 'DataUploadProcessing') {
          let inqIndex = inq.length > 0 ? inq.findIndex(x => (this.menus[0].removeNumberFromText(x.title + '')).trim().toLowerCase() == flMenMenuJsonObj.menuHeaderTitle.trim().toLowerCase()) : -1;
          // console.log("Suppliers Menues", inq, inqIndex);
          inqIndex > -1 ? inq[inqIndex].setAsActive() : false;
        }
      }

    }
  }
  /**
   * Fetch menues title from configration (default/domian-menu-mapping.config.json) file
   * @param moduleKey
   * @param menuHeaderKey
   * @param menu_mapping_Obj
   * @returns menu object with header and values
   */
  getModuleWiseMenusTitle(moduleKey, menuHeaderKey, menu_mapping_Obj: menuMapping) {
    let obj: { moduleTitle, menuHeaderTitle, entryMenuTitle, editByColName, values?} = {
      moduleTitle: '',
      menuHeaderTitle: '',
      entryMenuTitle: '',
      editByColName: '',
      values: []
    };
    let moduleJSONObj = menu_mapping_Obj.menus.find(x => x.parent_key == moduleKey);
    obj.moduleTitle = moduleJSONObj.title;
    let jsonCustomerObj = moduleJSONObj.values.find(x => x.key_header == menuHeaderKey);
    obj.menuHeaderTitle = jsonCustomerObj.title_header;
    obj.editByColName = jsonCustomerObj.editByColName;
    let entry;
    if (jsonCustomerObj.values.length == 0) {
      entry = menu_mapping_Obj.entryEditEnquiryMenuTemplate.find(x => x.key == 'entry')?.title;
      obj.values = menu_mapping_Obj.entryEditEnquiryMenuTemplate;
    } else {
      entry = jsonCustomerObj.values.find(x => x.key == 'entry')?.title;
      obj.values = jsonCustomerObj.values;
    }
    obj.entryMenuTitle = entry;
    return obj;
  }
  /**
   * for getting specific menues filter by menu header
   * @param childHeader menu header title
   * @returns
   */
  getSubMenues(childHeader) {
    childHeader = childHeader.toLowerCase();
    let tempObj;
    tempObj = Object.assign([],
      this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
        .childs.find(x => ((x.title + '').trim().toLowerCase()) == childHeader)
    );

    return tempObj;
  }
  /**
   * Filter and get Processing related menus
   * @param menuHeaderTitle header title
   * @returns
   */
  getProcessingMenu(menuHeaderTitle) {
    let tempObj = Object.assign([],
      this.menus.find(x => (x.SubMenues.find(y => y.isActive == true) != undefined)).SubMenues.find(y => y.isActive == true)
        .childs.filter(x => (this.menus[0].removeNumberFromText(x.title + '').trim().toLowerCase()) == (menuHeaderTitle.trim().toLowerCase()))[0].childs
    );
    return tempObj;
  }
  //for showing Submenus panel
  showMenusPanel(menuIndex) {
    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].isActiveForChildVisibility = false;
    }
    if (menuIndex != undefined) this.menus[menuIndex].isActiveForChildVisibility = true;
  }
  /**
   * This method resets Menu and submenu activation styling
   * @param requestedParent  parent title
   * @param requestedMenuIndex submenu menu index
   * @returns true/false
   */
  resetAllMenuesonSubChildLoad(requestedParent, requestedMenuIndex) {
    let currentLoadedChildIndex;
    let currentParentIndex;
    this.menus.forEach((element, index) => {
      let activeSubMen = element.SubMenues.findIndex(x => x.isActive == true);
      if (activeSubMen !== -1) {
        currentLoadedChildIndex = activeSubMen;
        currentParentIndex = index;
      }
    });
    if (currentParentIndex !== undefined && currentLoadedChildIndex !== undefined) {
      if (currentLoadedChildIndex != requestedMenuIndex || currentParentIndex != requestedParent) {
        this.menus[currentParentIndex].SubMenues[currentLoadedChildIndex].setAsDeActive();
        this.menus[currentParentIndex].SubMenues[currentLoadedChildIndex].childs = [];
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }
  /**
   * Set icons on main menu as per their titles
   */
  setIcons() {

    for (let i = 0; i < this.menus.length; i++) {
      let _title = ((this.menus[i].title + '').trim().toLowerCase());
      if (_title == 'client selection') {
        this.menus[i].iconUrl = 'assets/icon/ClientsLogo.svg';
      } else if (_title == 'operations') {
        this.menus[i].iconUrl = 'assets/icon/menu/Operations.svg';//
      } else if (_title == 'account management') {
        this.menus[i].iconUrl = 'assets/icon/menu/Account-Management.svg';//
      } else if (_title == 'inquiries and reports') {
        this.menus[i].iconUrl = 'assets/icon/menu/Inquiries-Reports.svg';//
      } else if (_title == 'po inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/po-inquiries.svg';
      } else if (_title == 'arn inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/ARN-Inquiries1.svg';
      } else if (_title == 'receipt inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/Receipt-Inquires1.svg';
      } else if (_title == 'open order inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/Order-Inquires1.svg';
      } else if (_title == 'shipment inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/Shipment-Inquiries1.svg';
      } else if (_title == 'bol inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/bol-inquiries.svg';
      } else if (_title == 'utilities') {
        this.menus[i].iconUrl = 'assets/icon/menu/utility.svg';
      } else if (_title == 'order processing') {
        this.menus[i].iconUrl = 'assets/icon/menu/order-processing.svg';
      } else if (_title == 'products') {
        this.menus[i].iconUrl = 'assets/icon/menu/product.svg';
      } else if (_title == 'arn processing') {
        this.menus[i].iconUrl = 'assets/icon/menu/arn-processing.svg';
      } else if (_title == 'order inquiries') {
        this.menus[i].iconUrl = 'assets/icon/menu/Order-Inquires1.svg';
      } else if (_title == 'master imports w/validation') {
        this.menus[i].iconUrl = 'assets/icon/menu/Master imports.svg';
      } else if (_title == 'standard master imports') {
        this.menus[i].iconUrl = 'assets/icon/menu/Standard master imports.svg';
      } else if (_title == 'mobile') {
        this.menus[i].iconUrl = 'assets/icon/menu/mobile.svg';
      } else {
        this.menus[i].iconUrl = 'assets/icon/menu/Inquiries-Reports.svg';
      }
    }
  }
}
/**
 * Main Menu items  Schema
 */
export class clsMenuItem {
  cmd = ''
  title = '';
  panelActive;
  iconUrl = '';
  tooltip = '';
  isWeb = true;
  hasPanel = false;
  isCap = false;
  SubMenues: clsSubMenu[] = [];
  isActiveForChildVisibility: boolean = false;
  get isClientSelectionMenu() {
    return ((this.title + '').trim().toLowerCase() == 'client selection');
  }
  constructor(private subMenuReq, public menu_mapping_Obj: menuMapping) { }
  loadSubMenus(subMenuIndex, navigationStack: string[], parentIsGrid?, gridItemIndex?, responseLines?) {

    //by Ali Bhai

    return new Observable<any>(observer => {

      this.subMenuReq(navigationStack, parentIsGrid ? this.SubMenues[subMenuIndex].childs[gridItemIndex] : this.SubMenues[subMenuIndex], responseLines).subscribe(

        y => {

          if (y.type && y.type == 'WorkArea') {
            // console.log("Got Work Area", y);
            if (!y.inBetweenMenuScreen) {
              this.afterSubMenuLoaded(subMenuIndex);
            }
            observer.next(y);
            observer.complete();
          } else {
            this.afterSubMenuLoaded(subMenuIndex);
            // observer.next({ type: 'preselected', menus: y });
            observer.next();
            observer.complete();
          }
        }
      );
    })

  }
  afterSubMenuLoaded(subMenuIndex) {

    if (this.SubMenues[subMenuIndex].title.includes('Inventory Management')) {
      for (let i = 0; i < this.SubMenues[subMenuIndex].childs.length; i++) {
        for (let j = 0; j < this.SubMenues[subMenuIndex].childs[i].childs.length; j++) {
          if ((this.SubMenues[subMenuIndex].childs[i].childs[j].title + '').toLowerCase().includes('inquiries')) {
            this.SubMenues[subMenuIndex].childs[i].childs[j].shouldRender = false;
          }
        }
      }
      this.SubMenues[subMenuIndex].childs.forEach(child => {
        let tempMenu = new clsSubMenu();
        tempMenu.title = 'All inquiries';
        tempMenu.shouldRenderOptions = false
          ; tempMenu.tooltip = "All inquiries";
        tempMenu.shouldOpenCustomForm = true;
        tempMenu['isInqAll'] = true;
        tempMenu.customformJsonKey = "inventory_management";
        tempMenu.cmd = new clsCMD("noCmd", "noCmd")
        child.childs.push(tempMenu);
      })
    }
    if (this.SubMenues[subMenuIndex].title.includes('Shipment Processing')) {
      for (let i = 0; i < this.SubMenues[subMenuIndex].childs.length; i++) {
        if ((this.SubMenues[subMenuIndex].childs[i].title + '').toLowerCase().includes('inquiries')) {
          this.SubMenues[subMenuIndex].childs[i].shouldRender = false;
        }
      }
      let isInquiresHided = this.SubMenues[subMenuIndex].childs.find(x => x.shouldRender == false && (x.title + '').toLowerCase().includes('inquiries'));
      if (isInquiresHided) {
        let tempMenu = new clsSubMenu(); tempMenu.title = 'All inquiries';
        tempMenu.shouldRenderOptions = false; tempMenu.tooltip = "All inquiries";
        tempMenu.shouldOpenCustomForm = true;
        tempMenu['isInqAll'] = true;
        tempMenu.customformJsonKey = "shipment_processing";
        tempMenu.cmd = new clsCMD("noCmd", "noCmd")
        this.SubMenues[subMenuIndex].childs.push(tempMenu);
      }
    }
    if (this.SubMenues[subMenuIndex].title.includes('System Manager Maintenance')) {
      this.SubMenues[subMenuIndex].childs.forEach(el => {
        if (el.title == 'Clear ALL & Load Demo Data') {
          el.shouldRender = false;
        }
      });
    }
    if (this.SubMenues[subMenuIndex].title.includes('Allocation Management')) {
      this.SubMenues[subMenuIndex].childs.forEach(el => {
        if (el.title == 'Grouped Allocations') {
          el.childs.forEach(elCh => {
            if (elCh.title.includes('Shipment Processing')) {
              elCh.shouldRender = false;
            }
          });
        }
      });
    }
    //Hide Link To Menus
    for (let i = 0; i < this.SubMenues[subMenuIndex].childs.length; i++) {
      if (this.SubMenues[subMenuIndex].childs[i].isGridBasedMenu) {
        for (let j = 0; j < this.SubMenues[subMenuIndex].childs[i].childs.length; j++) {
          for (let k = 0; k < this.SubMenues[subMenuIndex].childs[i].childs[j].childs.length; k++) {
            if (this.SubMenues[subMenuIndex].childs[i].childs[j].childs[k].title.trim().toLowerCase().startsWith('link to ')) {
              this.SubMenues[subMenuIndex].childs[i].childs[j].childs[k].shouldRender = false;
            }
          }
        }

      } else {
        for (let j = 0; j < this.SubMenues[subMenuIndex].childs[i].childs.length; j++) {
          if (this.SubMenues[subMenuIndex].childs[i].childs[j].title.trim().toLowerCase().startsWith('link to ')) {
            this.SubMenues[subMenuIndex].childs[i].childs[j].shouldRender = false;
          }
        }
      }

    }
    /**Moved Hardcoded menu toJSON and comparing text from that File*/
    let fileMaintainanceMenu = this.menu_mapping_Obj.menus.find(x => x.parent_key == 'file_maintenance');
    if (this.SubMenues[subMenuIndex].title.includes(fileMaintainanceMenu.title)) {
      fileMaintainanceMenu.values.forEach(subMenuJSN => {
        if (subMenuJSN.key_header == "products" || subMenuJSN.key_header == "customers" ||
          subMenuJSN.key_header == "suppliers" || subMenuJSN.key_header == "bin_schedules" ||
          subMenuJSN.key_header == "inventory_accounts" || subMenuJSN.key_header == "bin_locations_global"
          || subMenuJSN.key_header == "inventory_safety_stock" || subMenuJSN.key_header == "commodity_codes"
          || subMenuJSN.key_header == "inv_acct_assign_priority") {
          let objFileMaintChild = this.SubMenues[subMenuIndex].childs.find(x => this.compareMenuTitle(subMenuJSN.title_header, x.title));
          if (objFileMaintChild) {
            if (subMenuJSN.values.length == 0) subMenuJSN.values = this.menu_mapping_Obj.entryEditEnquiryMenuTemplate;
            subMenuJSN.values.forEach(childJSON => {
              let objCh = objFileMaintChild.childs.find(x => this.compareMenuTitle(childJSON.title, x.title));
              if (objCh) {
                objCh.shouldRender = false;
              }
            });
            if ((objFileMaintChild.childs.find(x => x.shouldRender))) {
              objFileMaintChild.shouldOpenCustomForm = true;
              objFileMaintChild.customformJsonKey = subMenuJSN.key_header;
            }
            if ((objFileMaintChild.childs.find(x => x.shouldRender) == undefined)) {
              objFileMaintChild.shouldRenderOptions = false;
              objFileMaintChild.customformJsonKey = subMenuJSN.key_header;
            }
          }
        }
      });
    }
    let orderShipmentProcessing = this.menu_mapping_Obj.menus.find(x => x.parent_key == 'orderShipment');
    if (this.compareMenuTitle(orderShipmentProcessing.title, this.SubMenues[subMenuIndex].title)) {
      for (let i = 0; i < this.SubMenues[subMenuIndex].childs.length; i++) {
        if ((this.SubMenues[subMenuIndex].childs[i].title + '').toLowerCase().includes('inquiries')) {
          this.SubMenues[subMenuIndex].childs[i].shouldRender = false;
        }
      }
      let isInquiresHided = this.SubMenues[subMenuIndex].childs.find(x => x.shouldRender == false && (x.title + '').toLowerCase().includes('inquiries'));
      if (isInquiresHided) {
        let tempMenu = new clsSubMenu(); tempMenu.title = 'All inquiries';
        tempMenu.shouldRenderOptions = false; tempMenu.tooltip = "All inquiries";
        tempMenu.shouldOpenCustomForm = true;
        tempMenu['isInqAll'] = true;
        tempMenu.customformJsonKey = "order_processing";
        tempMenu.cmd = new clsCMD("noCmd", "noCmd")
        this.SubMenues[subMenuIndex].childs.push(tempMenu);
      }
      let orderProc = orderShipmentProcessing.values.find(x => x.key_header == "order_processing");
      this.SubMenues[subMenuIndex].childs.forEach(el => {
        //orderShipmentProcessing.val
        if (this.compareMenuTitle(orderProc.title_header, el.title)) {
          orderProc.values.forEach(aPoc => {
            let isExist = el.childs.find(z => this.compareMenuTitle(aPoc.title, z.title));
            if (isExist) isExist.shouldRender = false;
          });
          if ((el.childs.find(x => x.shouldRender)) == undefined) {
            el.shouldRenderOptions = false;
          }
          if ((el.childs.find(x => x.shouldRender == false))) {
            el.shouldOpenCustomForm = true;
            el.customformJsonKey = orderProc.key_header;
          }
        }
      });
    }
    let arnProcessing = this.menu_mapping_Obj.menus.find(x => x.parent_key == 'arnRecieptProcessing');
    if (this.compareMenuTitle(arnProcessing.title, this.SubMenues[subMenuIndex].title)) {
      for (let i = 0; i < this.SubMenues[subMenuIndex].childs.length; i++) {
        if ((this.SubMenues[subMenuIndex].childs[i].title + '').toLowerCase().includes('inquiries')) {
          this.SubMenues[subMenuIndex].childs[i].shouldRender = false;
        }
      }
      let isInquiresHided = this.SubMenues[subMenuIndex].childs.find(x => x.shouldRender == false && (x.title + '').toLowerCase().includes('inquiries'));
      if (isInquiresHided) {
        let tempMenu = new clsSubMenu(); tempMenu.title = 'All inquiries';
        tempMenu.shouldRenderOptions = false; tempMenu.tooltip = "All inquiries";
        tempMenu.shouldOpenCustomForm = true;
        tempMenu['isInqAll'] = true;
        tempMenu.customformJsonKey = "arn_processing";
        tempMenu.cmd = new clsCMD("noCmd", "noCmd")
        this.SubMenues[subMenuIndex].childs.push(tempMenu);
      }
      let arnProc = arnProcessing.values.find(x => x.key_header == "arn_processing");
      this.SubMenues[subMenuIndex].childs.forEach(el => {
        //orderShipmentProcessing.val
        if (this.compareMenuTitle(arnProc.title_header, el.title)) {
          arnProc.values.forEach(aPoc => {
            let isExist = el.childs.find(z => this.compareMenuTitle(aPoc.title, z.title));
            if (isExist) isExist.shouldRender = false;
          });
          if ((el.childs.find(x => x.shouldRender)) == undefined) {
            el.shouldRenderOptions = false;
            el.customformJsonKey = arnProc.key_header;
          }
          if ((el.childs.find(x => x.shouldRender == false))) {
            el.shouldOpenCustomForm = true;
            el.customformJsonKey = arnProc.key_header;
          }
        }
      });
    }
    let dataUplodProc = this.menu_mapping_Obj.menus.find(x => x.parent_key == 'DataUploadProcessing')
    if (this.compareMenuTitle(dataUplodProc.title, this.SubMenues[subMenuIndex].title)) {
      this.SubMenues[subMenuIndex].customformJsonKey = dataUplodProc.parent_key;
      dataUplodProc.values.forEach(dup => {
        if (dup.key_header == 'file_transfer_to_environment' ||
          dup.key_header == 'order_shipment_processing_menu' ||
          dup.key_header == 'ARN_eceipt_rocessing_menu' || dup.key_header == 'files_sent_to_environment'
        ) {
          let menExist = this.SubMenues[subMenuIndex].childs.find(z => this.compareMenuTitle(dup.title_header, z.title));
          if (menExist) {
            if (dup.key_header == 'files_sent_to_environment') {
              let tempObj = dataUplodProc.values.find(y => y.key_header == 'file_transfer_to_environment');
              if (tempObj) {
                menExist.shouldOpenCustomForm = true;
                menExist.customformJsonKey = dup.key_header;
              }
            } else {
              menExist.shouldRender = false;
            }
          }
        }
      })
    }

    this.SubMenues[subMenuIndex].setAsActive();
  }
  resetChildMenusActivate(index, isGridBasedMenu?) {
    for (let i = 0; i < this.SubMenues[index].childs.length; i++) {
      if (isGridBasedMenu) {
        this.SubMenues[index].childs[i].childs = [];
        this.SubMenues[index].childs[i].setAsDeActive();
      } else {
        this.SubMenues[index].childs[i].setAsDeActive();
      }
    }
  }
  compareMenuTitle(source = "", target = "") {
    if (source == undefined || target == undefined || source == "" || target == "") return false;
    let plaintext = this.removeNumberFromText(target);
    return plaintext.toLowerCase() == (source + '').trim().toLowerCase()
  }
  removeNumberFromText(text) {
    if (text.indexOf(' - ') === -1) {
      return text;
    } else {
      let frag = text.split('-');
      let num = +(frag[0] + '').trim();
      if (isNaN(num)) {
        return text;
      } else {
        return text.slice(text.indexOf('-') + 1).trim()
      }

    }
  }
  // resetSubMenuActivation(){
  //   this.SubMenues.forEach(x=>{
  //     x.resetMenuActivation();
  //     x.resetChilds();
  //     console.log(this.SubMenues)
  //   })
  // }
}
/**
 * Submenu Items schema
 */
export class clsSubMenu {
  //Need to remove
  shouldRenderOptions: boolean = true;
  shouldOpenCustomForm: boolean = false;
  customformJsonKey: string = undefined;
  title;
  tooltip;
  cmd: clsCMD;
  shouldRender = true;
  applyActiveClass = false;
  private _isActive = false;
  childs: clsSubMenu[] = [];
  isGridBasedMenu: boolean = false;
  toggleChild: boolean = false;
  constructor() {

  } //public subMenuReq,parent?:clsSubMenu
  get isActive() {
    return this._isActive;
  }

  setAsActive(childIndex?) {

    if (childIndex != undefined) {
      for (let i = 0; i < this.childs.length; i++) {
        if (i != childIndex) {
          this.childs[i].setAsDeActive();
        }
      }
      this.childs[childIndex].setAsActive();
    }
    this._isActive = true;
    //   this.toggleChild = true;
    // this.subMenuReq(this.cmd.value,this).subscribe();
  }
  setAsDeActive() {
    for (let i = 0; i < this.childs.length; i++) {
      this.childs[i].setAsDeActive();
    }
    this._isActive = false;
    //    this.toggleChild = false;
    this.applyActiveClass = false;
  }


  setAsActiveArrowToggle(childIndex?) {

    if (childIndex != undefined) {
      for (let i = 0; i < this.childs.length; i++) {
        if (i != childIndex) {
          this.childs[i].setAsDeActiveArrowToggle();
        }
      }
      this.childs[childIndex].setAsActiveArrowToggle();
    }

    this.toggleChild = true;

  }
  setAsDeActiveArrowToggle() {
    for (let i = 0; i < this.childs.length; i++) {
      this.childs[i].setAsDeActiveArrowToggle();
    }

    this.toggleChild = false;

  }

}
/**
 * Command schema
 */
export class clsCMD {
  constructor(public value, public alias) { }
}

