import { Component, Pipe, PipeTransform, OnInit, OnChanges, Input, ViewChild, ViewChildren, QueryList, TemplateRef, ElementRef, ChangeDetectorRef, SimpleChanges, Renderer2, Output, EventEmitter, Attribute, ContentChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { items, Orders } from "./model/grid-data.model";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RowActionButtonDirective } from './row-action-button.directive';
import { GridRowService } from './services/grid-row.service';
// import { viewClassName } from '@angular/compiler';
import { MatMenuTrigger } from '@angular/material/menu';
import { UtilityService } from '@app/core';
@Component({
  selector: 'v3-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [GridRowService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GridComponent implements OnInit, OnChanges {
  @Input('GridData') set gridData(value) {
    this.orders = value;
    this.initGridData();
    // console.log("after Search fata binded prop exicuted");
  }
  sortable: [];
  defaultSort;
  expanded: boolean = false;
  @Input() hasMoreHadder: boolean = true;
  @Input('hasMoreRows') hasMoreRows: boolean = false;
  @Input('ShowEllipses') ShowEllipses: boolean = true;
  _rowContextItems = [];
  @Input('ShowContext') _showContext = new Notifier(); // Object to call Show Context Menu
  @Output('onContextMenuRequest') _onContextMenuRequest = new EventEmitter<any>();
  @Output('onRowContextItemAction') _rowContextItemAction = new EventEmitter<any>();
  @Input('disable') disabled: boolean = false;
  @Input('MasterColumns') set _columnsToDisplay(value: Array<any>) {
    if (value && value.length > 0) {
      let expendIconIndex = value.findIndex(x => x.field == "expandIcon");
      if (expendIconIndex !== -1) {
        value.splice(expendIconIndex, 1);
        console.error("Grid Implimentation Depricated::\nThis method of defining '{... field:'expandIcon'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Remove this object from array.\nGrid Control will automatically display expandable row action button id it will find detail view Columns defination.");
      }
      let pivotIndex = value.findIndex(x => x.field == "pivot");
      if (pivotIndex !== -1) {
        value.splice(pivotIndex, 1);
        console.error("Grid Implimentation Depricated::\nThis method of defining '{... field:'pivot'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Remove this object from array because its a default functionality of Grid control.");
      }
      let selectIndex = value.findIndex(x => x.field == "select");
      if (selectIndex !== -1) {
        value.splice(selectIndex, 1);
        console.error("Grid Implimentation Depricated::\nThis method of defining '{... field:'select'....}' column  in '[MasterColumns]' object array is depricated,\n Kindly Provide attribute property in grid selector <v3-grid 'SelectableRows='true'> for adding Row Selection column.");
      }
      //console.log("All Finded ArrayIndex",expendIconIndex,pivotIndex,selectIndex);
      this.columnsToDisplay = Object.assign([], value);
      // console.log(this.columnsToDisplay,"After Removed Developers Column")
      this.updateColumnsData();
    } else {
      this.initColumns = [];
      this.columnsToDisplay = [];
    }
  }

  columnsToDisplay: any = [];
  initColumns = [];
  sortedData;
  orders;
  selection = new SelectionModel<Orders>(true, []);

  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<items>>;
  @ViewChild('overview') private overview: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Orders>;
  usersData: any[] = [];
  key;
  num = 1;
  valu;
  reverse: boolean;
  hasExpendedView = false;
  isSelectableRow;
  isSingleSelectablerow = false;
  lastSelectedRow = null;
  shiftSelectedRow = null;
  innerDisplayedColumns = [];
  @Input('InnerColumns') set _innerDisplayedColumns(value) {
    this.innerDisplayedColumns = value;
    this.columnKeys = this.innerDisplayedColumns.map(col => col.field);
    this.hasExpendedView = this.columnKeys && this.columnKeys.length > 0 ? true : false;
    //console.log("Inner columns",this.columnKeys)
  }
  ellipsesShow;
  columnKeys: any[] = [];
  expandedElement: Orders | null;
  @Output('LoadAllRows') LoadAllRows = new EventEmitter<any>();
  @Output('EditClicked') editClickedEvent = new EventEmitter<any>();
  @Output('ScrollControl') scrollControl = new EventEmitter<any>();
  @Output('RowsSelected') onRowsSelected = new EventEmitter<any>();
  @Output('RowSelected') rowSelected = new EventEmitter<any>();
  @Output('RowClicked') rowClicked = new EventEmitter<any>();
  @Output('CellClicked') cellClicked = new EventEmitter<any>();
  @Output('RowDoubleClciked') doubleClicked = new EventEmitter<any>();
  @ContentChild(RowActionButtonDirective, { static: false, read: TemplateRef }) rowActionButtons: TemplateRef<any>;
  /* This code is commented as PopoverController is an Ionic Control/Component */
  // constructor(public popoverCtrl: PopoverController, private cd: ChangeDetectorRef)
  constructor(private rowService: GridRowService, private cd: ChangeDetectorRef, private renderer: Renderer2, @Attribute('defaultSort') _defaultSort
    , @Attribute('SelectableRows') _isSelectableRow, @Attribute('SelectableRow') _isSingleSelectableRow,
    private utilityService: UtilityService) {
    this.defaultSort = _defaultSort;
    this.isSelectableRow = !_isSelectableRow ? false : (_isSelectableRow == 'true' ? true : false); //;
    this.isSingleSelectablerow = !_isSingleSelectableRow ? false : (_isSingleSelectableRow == 'true' ? true : false); //;
    //console.log("Selectable Attribure",this.isSelectableRow);
    this.rowService.selectedRowContext.subscribe(x => {
      this.rowSelected.emit(x);
      this.selection.clear();
      this.onRowsSelected.emit([]);
    });
  }

  ngOnInit() {
    // console.log('ellipses value', this.ShowEllipses);

    if (this.columnsToDisplay && this.columnsToDisplay.length !== 0) {
      this.updateColumnsData();
      this.initGridData();
      //this.dataSource.paginator = this.paginator;
      this.sort.sort({ id: this.defaultSort, start: 'asc', disableClear: false });
    }
    //  this.sort.sortChange.subscribe(x=>{
    //   console.log("Sorting of Grid on change",x);
    //  });
    this._showContext.valueChanged = (d) => {
      // console.log("Show context called", d);
      this.showContextMenu(d);
      // do something with the new value
    };
  }
  // @HostListener('window:scroll', ['scrollT'])
  scrollEvent(el: { _elementRef: ElementRef }) {
    if (this.dataSource.data.length <= 5000) {
      const tableViewHeight = el._elementRef.nativeElement.offsetHeight; // viewport
      const tableScrollHeight = el._elementRef.nativeElement.scrollHeight; // length of all table
      const scrollLocation = el._elementRef.nativeElement.scrollTop; // how far user scrolled

      // If the user has scrolled within 200px of the bottom, add more data
      const buffer = 200;
      const limit = tableScrollHeight - tableViewHeight - buffer;

      // console.log("Scroll Event - limit: " + limit + "clientHeight: " + el._elementRef.nativeElement.clientHeight + " offsetHeight: " + tableViewHeight + " scrollHeight: " + tableScrollHeight + " scrollTop: " + scrollLocation, el._elementRef.nativeElement, this.dataSource);

      if (limit > 0 && scrollLocation > limit) {
        this.scrollControl.emit();
      }
    }
  }
  ngOnChanges(change: SimpleChanges) {
    // if (change.gridData?.currentValue) {
    //   if (change.gridData.currentValue.length != 0) {
    //     this.orders =  change.gridData.currentValue;
    //   } else {
    //     //this.orders = ORDERS;
    //   }
    //   this.initGridData();
    // }
  }

  onOrderId(element) {
    //TODO
  }
  drop(event: CdkDragDrop<string[]>) {
    if (this.columnsToDisplay[event.currentIndex].isDragable) {
      moveItemInArray(this.columnsToDisplay, event.previousIndex, event.currentIndex);
      //moveItemInArray(this.orders, event.previousIndex, event.currentIndex);
      this.updateColumnsData();
    }

    //console.log("Drag Drop:",event,this.columnsToDisplay)
  }
  colVisibilityChange(event) {
    console.log(event);
  }
  colsConfigOpend() {
    // console.log("Cols Config Opend");
    const panel = document.getElementsByClassName('mat-menu-panel') as any;
    if (panel && panel.length > 0) {
      this.renderer.setStyle(panel[0], 'max-height', '270px');
      //panel[0].style.height = '40% !important';
    }
    // console.log(panel)
  }
  trackByIndex(i) { return i; }
  updateColumnsData() {
    this.initColumns = [];
    this.selection.clear();
    this.onRowsSelected.emit([]);
    if (this.hasExpendedView == true) {
      this.initColumns.push('expandIcon');
    }
    if (this.isSelectableRow == true) {
      this.initColumns.push('select');
    }

    this.columnsToDisplay.forEach((col) => {
      if (col.isVisible) {
        this.initColumns.push(col.field);
      }
    });
    this.initColumns.push('pivot')
    // console.log("Init Coulmns", this.initColumns, this.columnsToDisplay);
  }
  initGridData() {
    this.usersData = [];
    this.selection.clear();
    this.onRowsSelected.emit([]);
    this.orders.forEach(user => {
      if (user.items && Array.isArray(user.items) && user.items.length) {
        this.usersData = [...this.usersData, { ...user, items: new MatTableDataSource(user.items) }];
      } else {
        this.usersData = [...this.usersData, user];
      }
    });
    this.dataSource = new MatTableDataSource(this.usersData);
    // console.log('this is dataSource', this.dataSource);
    this.dataSource.sort = this.sort;
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  toggleRow(element: Orders) {
    element.items && (element.items as MatTableDataSource<items>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<items>).sort = this.innerSort.toArray()[index]);
    this.overview.nativeElement.children[0].scrollLeft = 0;
    // console.log("End of Expended View Exicution", element, this.dataSource, this.innerDisplayedColumns);
  }

  applyFilter(filterValue: string) {
    // this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<lines>).filter = filterValue.trim().toLowerCase());
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection.*/

  masterToggle() {
    this.isAllSelected() ?
      (this.selection.clear()) :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.onRowsSelected.emit(this.selection.selected);
    this.rowSelected.emit(undefined);
    this.rowService.rowSelected.emit(undefined);
  }
  rowToogle(row) {

    if (this.utilityService.shiftPressed)
      var shift = true;

    if (!shift) {
      this.lastSelectedRow = row.index;
      shift = false;
    }

    if (shift) {
      this.shiftSelectedRow = row.index;
      if (this.shiftSelectedRow < this.lastSelectedRow)
        [this.shiftSelectedRow, this.lastSelectedRow] = [this.lastSelectedRow, this.shiftSelectedRow]
      for (let i = this.lastSelectedRow; i < this.shiftSelectedRow; i++) {
        this.selection.select(this.dataSource.data[i]);
      }
    }

    this.selection.toggle(row)
    this.onRowsSelected.emit(this.selection.selected);
    this.rowSelected.emit(undefined);
    this.rowService.rowSelected.emit(undefined);
  }

  checkBoxDoubleClicked(event) {
    event.stopPropagation();
  }

  editClicked(row) {
    this.editClickedEvent.emit(row);
  }

  CellClicked(fieldName, rowData, RowClickable: boolean, detailViewActionButton: boolean, rowId) {
    if (RowClickable == true) {
      this.cellClicked.emit({ field: fieldName, data: rowData, fieldValue: rowData[fieldName] });

    }
    if (detailViewActionButton == true) {
      this.toggleRow(rowData);
    }
    this.rowClicked.emit(rowData);
    this.rowService.rowSelected.emit(rowId);
  }
  rowDoubleClicked(event) {
    // console.log("Row click of Grid", event)
    this.selection.toggle(event);
    this.onRowsSelected.emit(this.selection.selected);
    this.doubleClicked.emit(event);
  }
  /* Pivot component*/

  async openMenu(ev: any) {
    const customizableColumns = this.columnsToDisplay.filter((col) => col.isDragable);

    //#region This code is commented as PopoverController is an Ionic Control/Component

    // const popover = await this.popoverCtrl.create({
    //   component: GridPivotComponent,
    //   componentProps: {
    //     columsList: customizableColumns,
    //     homeRef: this,
    //   },
    //   event: ev,
    //   animated: true,
    //   showBackdrop: false,
    // });
    // popover.style.cssText = '--max-width: 200px; --max-height: 300px';

    // return await popover.present();

    //#endregion

  }

  async onMoreOption(ev: any) {

    //#region This code is commented as PopoverController is an Ionic Control/Component
    // const popover = await this.popoverCtrl.create({
    //   component: MoreOptionPopoverComponent,
    //   cssClass: 'grid-more-options-popover',
    //   event: ev,
    //   translucent: true
    // });
    // popover.style.cssText = '--max-width: 150px; --max-height: 200px';
    // return await popover.present();
    //#endregion
  }

  toggleColumn(column) {
    const temp = [...this.columnsToDisplay];
    const index = temp.findIndex(col => col.field === column.field);
    temp[index].isVisible = column.isVisible;
    this.columnsToDisplay = temp;
    this.updateColumnsData();
  }
  repositionColumns(oldIndex, newIndex) {
    const temp = [...this.columnsToDisplay];
    this.move(temp, oldIndex, newIndex);
    this.columnsToDisplay = temp;
    this.updateColumnsData();
  }

  move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  scrollTop(el) {
    // console.log('this id the value', el._elementRef.nativeElement.scrollTop);
    el._elementRef.nativeElement.scrollTop = 0;
  }
  scrollBottom(el) {
    // console.log('this id the value', el._elementRef.nativeElement.scrollHeight);
    el._elementRef.nativeElement.scrollTop = el._elementRef.nativeElement.scrollHeight;
  }
  // headerValue1;
  // indexValue;

  // initHeaderValue(pl?){
  //   console.log('this is the value for pl',pl);
  //   this.headerValue1 =pl.srcElement.innerText.trim();
  //   console.log('this is header value ',this.headerValue1);
  //   for(let i=0; i<=this.columnsToDisplay.length; i++){
  //         if(this.columnsToDisplay[i].displayName == this.headerValue1){
  //           this.indexValue = this.columnsToDisplay[i].field;
  //           console.log('this is for  value', this.columnsToDisplay[i]);
  //           console.log('this is for name value', this.columnsToDisplay[i].displayName);
  //         }
  //   }
  //   console.log('this is header index',this.indexValue);
  //   pl.preventDefault();
  // }
  sortDataAsc(columField) {
    // console.log("field", Object.assign('', this.contextMenuheaderBindedFieldName))
    if (this.sort.direction = "desc") {
      this.sort.sort({
        id: this.contextMenuheaderBindedFieldName,
        start: 'asc',
        disableClear: true,
      });
    }
  }
  sortDataDesc(columField) {
    // console.log("field", this.contextMenuheaderBindedFieldName)
    if (this.sort.direction = "asc") {
      this.sort.sort({
        id: this.contextMenuheaderBindedFieldName,
        start: 'desc',
        disableClear: true,
      });
    }
  }
  contextMenuheaderBindedFieldName;
  @ViewChild('headerMenuBtn')
  contextMenuHeader: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };
  onContextMenu(event: MouseEvent, HeaderField) {
    this.contextMenuheaderBindedFieldName = HeaderField;
    // console.log('works', event);
    event.preventDefault();
    this.contextMenuPosition.x = (event.x - 80) + "px";
    this.contextMenuPosition.y = (event.y) + "px";
    // console.log('x value', this.contextMenuPosition.x);
    // console.log('y value', this.contextMenuPosition.y);
    this.contextMenuHeader.menu.focusFirstItem('mouse');
    this.contextMenuHeader.openMenu();
  }
  rowContextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('RowMenuBtn')
  contextMenuRow: MatMenuTrigger;
  contextRowIndex = -1;
  onRowContextMenu(event: MouseEvent, row) {
    // console.log('Rows Context Menu worls,event::', event, row);
    event.preventDefault();
    this.rowContextMenuPosition.x = (event.x) + "px";
    this.rowContextMenuPosition.y = (event.y) + "px";
    // let showContextMenu= ([]) => (actions:[any]) =>{
    //   console.log("Call back functionn called",actions);

    // }
    this.contextRowIndex = row.index;
    this._onContextMenuRequest.emit({ rowNumber: row.index });

  }
  showContextMenu(actions) {
    this._rowContextItems = actions;
    this.contextMenuRow.menu.focusFirstItem('mouse');
    this.contextMenuRow.openMenu();
  }
  rowContextItemAction(item) {
    this._rowContextItemAction.emit({ cmd: item.cmd, rowNumber: this.contextRowIndex });
  }
}
export class Notifier {
  valueChanged: (data) => void = (d) => { };
}

