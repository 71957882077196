import { EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { ApienvService } from './apienv.service';
import { environment } from './../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '..';
import { V1PrinterService } from '@app/core/services/v1-printer.service';

@Injectable({
  providedIn: 'root'
})
export class V1UtilitiesService {

  constructor(
    private http: HttpClient,
    private env: ApienvService,
    public sanitizer: DomSanitizer,
    private v1PrinterService: V1PrinterService
  ) {
  }
  // constructor(private http: HttpClient, private env: ApienvService, public sanitizer: DomSanitizer, public authenticationService: AuthenticationService) { }

  /**
   * This method creates and download CSV file from data array
   * @param headers CSV data headers
   * @param data data inside CSV
   * @returns void
   */
  exportToCsv1(headers: any[], data: any[]) {
    let rightNow = new Date();
    let res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
    let timestamp = rightNow.getHours() + '' + rightNow.getMinutes() + '' + rightNow.getSeconds();
    let filename = 'fs_export.' + res + timestamp + '.csv';
    let rows = [];
    for (let i = 0; i < data.length; i++) {
      let lineItem = {};
      headers.forEach(x => {
        lineItem[x.displayName] = data[i][x.field];
      });
      rows.push(lineItem);
    }
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if ((<any>navigator).msSaveBlob) { // IE 10+
      (<any>navigator).msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  /**
   * This method parse text response into formated objects
   * @param res Request response in text format
   * @returns retrun array of commands with command argumnets in formated manner.
   */
  parseResponse(res): clsv1Response[] {
    let obj: clsv1Response[] = [];
    let splitedRes = typeof (res) == 'string' ? res.split('\n') : [];
    for (let i = 0; i < splitedRes.length; i++) {
      obj.push(
        new clsv1Response(
          splitedRes[i].substr(0, 3),
          splitedRes[i].substr(3)
        )
      );
    }
    return obj;
  }

  /**
   * This method parse and formates inputs/labels/fastscan/workarea objects from text format.
   * @param res Request response in text format
   * @param controls pass controls argument
   * @returns return workarea configuration/fastscan/input/label
   */
  RenderControls(res, controls) {
    let splitedByLineEnd: clsv1Response[] = this.parseResponse(res);
    let removeControls = [];
    let title = '';
    let waAction;
    let wareaId;
    let focusControl;
    let gridCols = [];
    let gridData = [];
    splitedByLineEnd.forEach(element => {
      if (element.code == '100') {
        let titleObj = element.value.split('|');
        waAction = titleObj[0];
        title = titleObj[6];
        wareaId = titleObj[1];
        if (waAction == '0') {
          gridData = [];
          gridCols = [];
          controls = []
        } else if (waAction == '1') {
          controls = [];
        }
        if (element.code == '101' || element.code == '102') {
          let obj = element.value.split('|');
          // let textval = obj[0].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
          let textval = obj[0].replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
          let nRow = +(obj[1].substring(obj[1].indexOf('r') + 1, obj[1].indexOf('c')));
          let nCol = obj[1].substring(obj[1].indexOf('c') + 1);
          let cType = element.code == '101' ? 'label' : 'input';
          let itemIndex = controls.findIndex(x => x.nRow == nRow);
          if (itemIndex !== -1) {
            controls[itemIndex].elements.push({
              cType: cType, cValue: textval, cCol: nCol, isLookUp: obj[3] == '1000' ? true : false, focus: false, extraParam: obj[2] + '|' + obj[3] + '|' + obj[4] + '|' + obj[5]
            });
          } else {
            controls.push({
              nRow: nRow,
              elements: [{ cType: cType, cValue: textval, cCol: nCol, isLookUp: obj[3] == '1000' ? true : false, focus: false, extraParam: obj[2] + '|' + obj[3] + '|' + obj[4] + '|' + obj[5] }]
            });
          }
        } else if (element.code == '107') {
          let k1 = element.value;
          if (k1 != '-1') {
            let tnRow = k1.substring(k1.indexOf('r') + 1, k1.indexOf('c'));
            let tnCol = k1.substring(k1.indexOf('c') + 1);
            removeControls.push({ r: tnRow, c: tnCol, id: k1 });
            //Removing Control From Renderd Control
            let rIndex = controls.findIndex(x => x.nRow == tnRow);
            if (rIndex !== -1) {
              let cIndex = controls[rIndex].elements.findIndex(y => y.cCol == tnCol);
              if (cIndex !== -1) {
                controls[rIndex].elements.splice(cIndex, 1);
                //controls[rIndex].elements[cIndex]={};
              }
            }
          }
        } else if (element.code == '108') {
          focusControl = element.value;
        }
        else if (element.code == '104') {
          let tempGrid = this.ParseGrid(element.value);
          let v3Config = this.GetV3FormatedGrid(tempGrid);
          // console.log("With in form Get this Grid", tempGrid, v3Config);
          gridCols = v3Config.cols;
          gridData = v3Config.data;
        } else if (element.code == '103') {
          let pBtnArray = element.value.split('|');
        }
      }
    });
    // console.log("Compiled Controls", controls, removeControls, title);
    for (let s = 0; s < controls.length; s++) {
      controls[s].elements.sort(function (t, u) {
        return t.cCol < u.cCol ? -1 : t.cCol > u.cCol ? 1 : 0;
      });
    }
    if (focusControl) {
      let fRow = focusControl.substring(focusControl.indexOf('r') + 1, focusControl.indexOf('c'));
      let fCol = focusControl.substring(focusControl.indexOf('c') + 1);
      for (let fe = 0; fe < controls.length; fe++) {
        if (fRow == controls[fe].nRow) {
          for (let ife = 0; ife < controls[fe].elements.length; ife++)
            if (controls[fe].elements[ife].cCol == fCol) {
              controls[fe].elements[ife].focus = true;
            }
        }
      }
    }

    return {
      title: title,
      controls: controls.sort(function (a, b) {
        return a.nRow < b.nRow ? -1 : a.nRow > b.nRow ? 1 : 0;
      }),
      removeControls: removeControls,
      waAction: waAction,
      grid: {
        cols: gridCols,
        data: gridData
      }
    }
  }
  /**
   * This function Parse Fastscan/table object from response text
   * @param response request response in text format
   * @returns  fastscan object with headers,data and fasctscan/table configration
   */
  ParseGrid(response) {
    // Delimiters
    let data = [];
    let dMaster = '~';
    let dRow = '|';
    let dCell = String.fromCharCode(127);
    let dImage = String.fromCharCode(3);

    let fastscanData = response.split(dMaster);
    let tableData = fastscanData[8].split(dRow);

    // Build Data Object
    let row = fastscanData[0];
    let col = fastscanData[1];
    let rows = fastscanData[2];
    let width = fastscanData[3];
    let search = (fastscanData[4] ? true : false);
    let mode = (fastscanData[5] == 0 ? false : true);
    let more = (fastscanData[6] == 0 ? true : false);
    let title = fastscanData[7];

    let headers = [];
    let load_all = false;
    for (var ii = 0; ii < tableData.length; ii++) {
      if (ii == 0) {
        var header = tableData[ii].split(dCell);
        for (var jj = 0; jj < header.length; jj++) {
          let headerObj: any = {};
          headerObj.name = header[jj].substring(4);
          headerObj.width = header[jj].substring(1, 4);
          headerObj.percent = (header.width / width * 100);
          //headerObj.type = (header[jj].substring(0,1) == 'C' ? 'char' : 'date');
          headers.push(headerObj);
        }

      } else {
        // Check the data for an image
        if (tableData[ii].indexOf(dImage) !== -1) {
          var image_row = tableData[ii].split(dCell);
          for (var cell_key in image_row) {
            if (image_row[cell_key].indexOf(dImage) !== -1) {

              // Is an image (uses dImage delimiter)
              if (!image_row[cell_key].match(/^\x03[0-9]{2}/)) {
                var imageData = image_row[cell_key];
                imageData = imageData.replace(dImage, '');
                imageData = imageData.split(';');

                var thumb, image = null;
                for (var image_key in imageData) {
                  if (imageData[image_key].substr(0, 1) == 'T')
                    thumb = imageData[image_key].substr(1);
                  else
                    image = imageData[image_key];
                }

                if (image == ' ') {
                  image = '/empty';
                }

                if (image || thumb) {
                  image_row[cell_key] = '<a href="images' + image + '?tn=' + encodeURIComponent('images' + thumb) + '" data-lightbox="fs_image"><img src="images' + thumb + '" class="thumbnail" width="64"></a>';
                }
              }
            }
          }
          tableData[ii] = image_row.join(dCell);
        }
        data.push(tableData[ii].split(dCell));
      }
    }
    return { headers, data, title, rows, search, mode, more };
  }
  /**
   * This function parse fastscan/table more object from response text
   * @param response request response in text format
   * @returns fastscan more object with headers,data and fasctscan/table configration
   */
  FastScanMore(response) {
    // Delimiters
    let dMaster = '~';
    let dRow = '|';
    let dCell = String.fromCharCode(127);
    let dImage = String.fromCharCode(3);

    let moreRows = (response.substring(0, 1) == 1 ? false : true);


    let tableData = response.substring(1).split(dRow);
    let rowsData = [];
    for (let ii = 0; ii < tableData.length; ii++) {
      // Check the data for an image
      if (tableData[ii].indexOf(dImage) !== -1 && !tableData[ii].match(/^[0-9]{1,2}/)) {
        let image_row = tableData[ii].split(dCell);

        for (var cell_key in image_row) {
          if (image_row[cell_key].indexOf(dImage) !== -1) {
            let imageData = image_row[cell_key];
            imageData = imageData.replace(dImage, '');
            imageData = imageData.split(';');

            let thumb, image = null;
            for (var image_key in imageData) {
              if (imageData[image_key].substr(0, 1) == 'T')
                thumb = imageData[image_key].substr(1);
              else
                image = imageData[image_key];
            }

            if (image == ' ') {
              image = '/empty';
            }

            if (image || thumb) {
              image_row[cell_key] = '<a href="images' + image + '?tn=' + encodeURIComponent('images' + thumb) + '" data-lightbox="fs_image"><img src="images' + thumb + '" class="thumbnail" width="64"></a>';
            }
          }
        }
        tableData[ii] = image_row.join(dCell);
      }
      rowsData.push(tableData[ii].split(dCell))
    }
    return { rowsData, moreRows }

  }
  /**
   * This function parse v1 formated Fastscan/table to v3-grid formated object
   * @param obj v1 formated fasctscan/table object
   * @param clickableColName Clickable Column name in v3-grid object
   * @param enableClickableCol Enable/Disable Clickable column .
   * @returns v3-frid formated Object
   */
  GetV3FormatedGrid(obj, clickableColName?, enableClickableCol?: boolean) {
    let cols: [any] = obj.headers.map((x, i) => {
      return {
        displayName: this.decodeHtmlEntities(x.name),
        field: 'index' + i,
        isVisible: true,
        isDragable: true,
        clickable: clickableColName !== undefined ? ((x.name == clickableColName) && enableClickableCol) : false
      }
    });
    let actionableColName = cols.find(x => x.clickable)?.field;
    let data = obj.data.map((x, i) => {
      let row = {};
      x.forEach((element, index) => {
        if (index !== 0) {
          row['index' + (index - 1)] = index == 2 ? element.trim().replace(/&amp;/g, '&') : element;
        }
      });
      row['index'] = i;
      actionableColName ? row['_ActionableColValue'] = (row[actionableColName] + '').trim() : false;
      return row;
    });
    return { cols, data, v1Obj: obj };
  }
  /**
   * This function parse v1 formated Fastscan/table more to v3-grid formated object
   * @param rows v1-formated more rows
   * @param headers v1-formated headers
   * @returns v3-grid formated object
   */
  GetV3FormatedMoreRows(rows: any[], headers) {
    let actionableColName = headers.find(x => x.clickable)?.field;
    let data = rows.map((x, i) => {
      let row = {};
      x.forEach((el, index) => {
        if (index === 0) {
          row['index'] = +(el + '').trim();
        } else {
          row['index' + (index - 1)] = el;
        }
      });
      actionableColName ? row['_ActionableColValue'] = (row[actionableColName] + '').trim() : false;
      return row;
    })
    return data;
  }
  /**
   * This method decodes Html entities from response text
   * @param str request reponse text
   * @returns decoded text
   */
  decodeHtmlEntities(str) {
    var element = document.createElement('div');
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    return str;
  }
  //-- Load External HTML/Js Files --//
  isSubdomainIsValidAndExist(subdomain) {
    let _Url = subdomain + '.spherewms.com';
    let headers = new HttpHeaders();
    headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept': '*/*'
    });
    return fetch(_Url, { mode: "no-cors" })
      .then(res => true)
      .catch(err => false)
  }
  isEnviornmentMigrated(envid) {
    const hostname = window && window.location && window.location.hostname;
    let _isLocalFeatureOrStaging = hostname.split('.').find(x => (x == this.env.featureDomainName || x.includes('localhost')));
    if (_isLocalFeatureOrStaging || envid == 'redaspen-s' || envid == 'redaspen' || envid == 'romeo') {
      return new Observable<any>((obser) => {
        obser.next('yes');
        obser.complete();
      });
    }
    else {
      let _Url = '/scripts/moved_v3_flag.php';
      let headers = new HttpHeaders();
      headers = new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded'
      });
      let bodyData = { account: envid };
      let body = new HttpParams();
      for (const k in bodyData) {
        body = body.set(k, bodyData[k]);
      }
      return this.http.post(this.env.API_Config.gemxUrl + _Url, body, { headers: headers, responseType: 'text' });
    }

  }
  encodeURLParamKeyValue(key, value) {
    return encodeURIComponent(key) + "=" + encodeURIComponent(value)
  }
  private GetBase64() {
    let Base64 = {
      _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/@",
      encode: function (e) {
        let t = "";
        let n, r, i, s, o, u, a;
        let f = 0;
        e = Base64._utf8_encode(e);
        while (f < e.length) {
          n = e.charCodeAt(f++);
          r = e.charCodeAt(f++);
          i = e.charCodeAt(f++);
          s = n >> 2;
          o = (n & 3) << 4 | r >> 4;
          u = (r & 15) << 2 | i >> 6;
          a = i & 63;
          if (isNaN(r)) {
            u = a = 64
          } else if (isNaN(i)) {
            a = 64
          }
          t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
        }
        return t
      },
      decode: function (e) {
        let t = "";
        let n, r, i;
        let s, o, u, a;
        let f = 0;
        e = e.replace(/[^A-Za-z0-9\+\/@]/g, "");
        while (f < e.length) {
          s = this._keyStr.indexOf(e.charAt(f++));
          o = this._keyStr.indexOf(e.charAt(f++));
          u = this._keyStr.indexOf(e.charAt(f++));
          a = this._keyStr.indexOf(e.charAt(f++));
          n = s << 2 | o >> 4;
          r = (o & 15) << 4 | u >> 2;
          i = (u & 3) << 6 | a;
          t = t + String.fromCharCode(n);
          if (u != 64) {
            t = t + String.fromCharCode(r)
          }
          if (a != 64) {
            t = t + String.fromCharCode(i)
          }
        }
        t = Base64._utf8_decode(t);
        return t
      },
      _utf8_encode: function (e) {
        e = e.replace(/\r\n/g, "\n");
        let t = "";
        for (let n = 0; n < e.length; n++) {
          let r = e.charCodeAt(n);
          if (r < 128) {
            t += String.fromCharCode(r)
          } else if (r > 127 && r < 2048) {
            t += String.fromCharCode(r >> 6 | 192);
            t += String.fromCharCode(r & 63 | 128)
          } else {
            t += String.fromCharCode(r >> 12 | 224);
            t += String.fromCharCode(r >> 6 & 63 | 128);
            t += String.fromCharCode(r & 63 | 128)
          }
        }
        return t
      },
      _utf8_decode: function (e) {
        let t = "";
        let n = 0;
        let r, c1, c2, c3 = 0;
        while (n < e.length) {
          r = e.charCodeAt(n);
          if (r < 128) {
            t += String.fromCharCode(r);
            n++
          } else if (r > 191 && r < 224) {
            c2 = e.charCodeAt(n + 1);
            t += String.fromCharCode((r & 31) << 6 | c2 & 63);
            n += 2
          } else {
            c2 = e.charCodeAt(n + 1);
            c3 = e.charCodeAt(n + 2);
            t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
            n += 3
          }
        }
        return t
      }
    }
    return Base64;
  }
  encodeStringForQueryParam(string: string) {
    // Define the string
    //let string = '~!@#$%^&*()_+{}|":?><`1234567890-=\][;/., Hello World';
    // Encode the String
    const searchRegExpForEqual = /=/g;
    const replaceWithForEqual = '[[equal]]';
    // const searchRegExpForSlash = /\//gi;
    // const replaceWithForSlash = '[[equal]]';
    string = string.replace(searchRegExpForEqual, replaceWithForEqual);
    let encodedString = this.GetBase64().encode(string);
    //console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"
    return encodedString;
  }
  dencodeStringForQueryParam(encodedString) {
    // Decode the String
    let decodedString = this.GetBase64().decode(encodedString);
    const searchRegExpForEqual = /\[\[equal\]\]/g;
    const replaceWithForEqual = '=';
    // const searchRegExpForSlash = /\//gi;
    // const replaceWithForSlash = '[[equal]]';
    decodedString = decodedString.replace(searchRegExpForEqual, replaceWithForEqual);
    return decodedString;
  }
  /**
   * This method creates observable for interacting with changing password API
   * @param bodyData Form data object for post
   * @returns observable for calling and subscribe
   */
  LoadChangePassScript(bodyData) {
    let _Url = '/scripts/change_password.php';
    let headers = new HttpHeaders();
    headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic 1234'
    });
    let body = new HttpParams();
    for (const k in bodyData) {
      body = body.set(k, bodyData[k]);
    }
    return this.http.post(this.env.API_Config.gemxUrl + _Url, body, { headers: headers, responseType: 'text' });
  }

  LoadPrinterSettingScript(bodyData) {
    let _Url = '/scripts/printer_settings.php';
    let headers = new HttpHeaders();
    headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic 1234'
    });
    let body = new HttpParams();
    for (const k in bodyData) {
      body = body.set(k, bodyData[k]);
    }
    return this.http.post(this.env.API_Config.gemxUrl + _Url, body, { headers: headers, responseType: 'text' });
  }

  // -- Upload file --//
  // -- Base URL for Upload file EndPoint --//
  private baseUrl = '/scripts/upload2.php';
  /**
   * This method is used to upload file to server
   * @param file file for uploading
   * @param Uniqueid unique id is mandatory for saving file on server
   * @returns observables for calling and subscribe
   */
  upload(file: File, Uniqueid): Observable<any> {
    const formData: FormData = new FormData();
    const modelData = new Blob([file]);
    formData.append('uploadtemplate', file);
    formData.append('uniqueid', Uniqueid);
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('X-Requested-With', 'XMLHttpRequest');
    header.append('responseType', 'text');


    let options = {
      headers: header
    }
    //const req = new HttpRequest('POST', `${this.baseUrl}`, formData,options);
    // return this.http.request(req);
    return this.http.post(this.env.API_Config.gemxUrl + this.baseUrl, formData, { ...options, responseType: 'text' });
  }
  /**
   * This function is for sending GemPad request
   * @param bodyData Formdata of Gampad text
   * @returns observables for calling and subscribe
   */
  GemPadRequest(bodyData) {
    let gemPadUrl = '/scripts/gempad.php';
    // Request headers
    let headers = new HttpHeaders();
    headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    });
    // Request body
    let body = new HttpParams();
    for (const k in bodyData) {
      body = body.set(k, bodyData[k]);
    }
    //let v1url="https://v1.spherewms.com/scripts/gempad.php";
    // Return the request
    return this.http.post(this.env.API_Config.gemxUrl + gemPadUrl, body, { headers: headers, responseType: 'text' });

  }
  /* Handles prints coming through the request url code */
  request_url_handler(url, brEnvironment, brUsername) {

    // Replace the hostname in URL responses
    // @todo Only replace for prints until AdHoc & Auth have been setup as Docker
    url = url.replace(/[\n\r]+/g, '');
    if (
      url.match(/\.pdf$/i) ||
      (url.match(/sphereprint/i) && !url.match(/sphereship/i)) ||
      url.match(/storage/i)
    ) {
      let baseUrl = this.env.API_Config.gemxUrl;
      url = url.replace(/^http(s)?\:\/\/.+?\//i, baseUrl + '/');
      url = url.replace(/^http(s)?\:\/\/(www|v1|uat).*\.(unicode|spherewms)\.com/i, '');
      url = url.replace(/[\n\r]+/g, '');
    }
    // For Local only
    if (url.match(/localhost/i)) {
      url = url.replace(/^http(s)?\:\/\/(localhost)/i, '');
      url = url.replace(/[\n\r]+/g, '');
      url = url;
    }

    // If this is a pdf file (Document Print)
    if (url.match(/\.pdf[\r]?$/i) !== null) {
      // Fix URL if necessary
      if (url.match(/^http(s)?\:\/\//i) || url.match(/(www)/i)) {
        url = url;
      } else if (url.substring(0, this.env.API_Config.gemxUrl.length) != this.env.API_Config.gemxUrl) {
        url = this.env.API_Config.gemxUrl + url
      }
      this.v1PrinterService.documentPrint(brEnvironment, brUsername, url);
	  // If this is a sphereprint print
    } else if (url.match(/sphereprint/i)) {
      this.v1PrinterService.labelPrint(brEnvironment, brUsername, url);
    // Any site to open other than a print
    } else {
      this.open_window(url);
    }
  }

  /**
   * This mentod opens new tab
   * @param url url of the  new tab
   * @param target target place to open new tab/window
   * @param options optional parameters
   */
  open_window(url, target?, options?) {
    if (url.match(/^http(s)?\:\/\//i) || url.match(/(www)/i)) {
      url = url;
    } else {
      url = this.env.API_Config.gemxUrl + url
    }
    window.open(url, target ? target : '_blank', "width=" + screen.availWidth + ",height=" + screen.availHeight + options ? options : "");
  }

  /**
   * Get property of Enviorntment URL
   */
  get _getEnvUrl() {
    return this.env.API_Config.gemxUrl;
  }
  /**
   *
   * @returns unique ID generator
   */
  guid() {
    return (String.fromCharCode(30 + Math.floor(Math.random() * 3)) + Date.now()).trim();
  }
}
/**
 * This class represent schema of request response
 */
export class clsv1Response {
  constructor(public code, public value) { }
}
/**
 * This class contains Workera Schema
 * This class handles Workarea as per response and commands an invoke corresponding event for further integration with UI elements
 * This class schema holds UI element's binding objects and their configuration and validations
 */
export class ScreenRenderer {
  _workAreaId;
  _isActive: boolean = true;
  _title;
  _controls = [];
  //true in case work area is Gempad
  _isGemPad = false;
  //array of buttons in workarea
  _buttons = [];
  //Array of sorted Input/Labels json binding object
  _controlElements = [];
  //Array of sorted Input/Labels binding object in different json hierarchy
  _controlElementsTemp = [];
  //Array of sorted Input/Labels binding object in different json hierarchy
  _controlLayout = [];
  //If Work area is type of option selection
  _isOptionSelectionScreen: boolean = false;
  //size of each group element
  eachColSize = 12;
  //Fastscan/Grid data
  _grid_Items = [];
  //Fastscan/Grid data
  _grid_columnsToDisplay = [];
  //Fastscan/Grid related configurations
  _gridProps = {
    visible_rows: 0,
    allow_search: true, //indicated searching is allowed in Fasct/Scan
    enable_multiSelect: true, //indicates multiselection rows are allowed or not
    available_more_rows: false //indicated is there more Rows exist in FasctScan for further loading (used in Lazy Loading)
  }
  //this contains Grid Row context menus list, if exist
  _gridContextMenus = [];
  //When Context menu checking reqest is sent and get response
  _gridContextMenuLoaded: boolean = false;
  _gemPadConfig = {
    send_response: 1, // 0=modeless, 1=response (send "[RAW]0" to complete)
    readonly: 1, // 0=write, 1=readonly
    diary_mode: 0, // 0=do nothing, add string + 2 EOLs at the top
    optional_diary_string: '', // diary string to add
    filename: '', // file name,
    fileContent: ''
  }
  //current Workeare Width compiled from response
  _panelWidth;
  //Set Column name that is clickable in Grid
  _GridClickableColName;
  //enable/disable click behaviour of clickable column in grid
  _enableClickableCol: boolean = true;
  //indicates about menu workarea
  _isMenuWorkArea: boolean = false;
  //indicates Fastscan/Grid has searching input
  _isSearchingInputExist: boolean = false;
  //Last Action type:
  //This is to defines about Action Type that has perform by user on last then we have utilized it for further new UX
  _lastPerformedAction: 'MsgBoxConfirmation' | 'ActionFromInputSubmission' = undefined;
  //executes when found new workarea in response
  public _addNewLayer(splitedByLineEnd: clsv1Response[]) { };
  //executes when found workarea removal workarea command in response
  public _removeLayer?(splitedByLineEnd: clsv1Response[], removeWorkAreaId) { };
  //executes when press Enter by user for this Workarea
  public _onEnterPress?(value: String, isLookUp?: boolean): void;
  //executes when found Message box in response in this workarea
  public _showMsgBox?(boxElements): void;
  //executes on line End of command 999
  public _onComplition?(splitedlines?): void;
  //executes on line End of command 999
  public __onComplition = new EventEmitter<any>();
  //executes when last action is of type ('MsgBoxConfirmation' | 'ActionFromInputSubmission')
  public _onFormSubmitionComplete?(): void;
  //executes if response has menus state 110 command
  public _stateIsMenuWorkArea?(splitedByLine: clsv1Response[]): void;
  //executes when found Gempad content
  public _loadGemPadContent?(): void;
  //executes when Fastscan/Grid has now rows , this method fires another request for fetching fasctscan/Grid data
  public _autoRequestOnEmptyGrid?(): void;
  //executes for printing request
  public _extraSyncRequestOnPrint?(cmd): void;
  //executes when found  multiple selected rows in response.
  public _returnMultipleSelection?(): void;
  //executes for printing request
  public _print_request?(url): void;
  //executes for new Upload File dialouge when found in response
  public _uploadDialouge?(res): void;
  //exicutes on QuickFunctions when found in response
  public _compileQuickFunctions?(): void;
  //executes when file downloading is completed
  public _fileDownloadedCompleted?(): void;
  //exicutes when found dashboard command in response
  public _redirectToDashboardComponent?(): void;
  /**
   *
   * @param v1Utility v1-utilitiy service that has functions for parsing text into object of strict type/json
   * @param __notifyOnProcessEnd_ofType type of workarea Ending action type
   */
  constructor(public v1Utility: V1UtilitiesService, public __notifyOnProcessEnd_ofType?: 'MsgBoxConfirmation' | 'ActionFromInputSubmission') {
  }
  /**
   * This function loop through all commands and parse data for bindings and executes coresponding method
   * for further binding with the UI or enhancing the UX
   * @param splitedByLineEnd formated response
   * @param onRenderNewLayer indicates weathe response should render on this object or not
   * @returns
   */
  _ParseScreen(splitedByLineEnd: clsv1Response[], onRenderNewLayer?: boolean) {
    let removeControls = [];
    let focusControl;
    //let splitedByLineEnd:clsv1Response[]=this.v1Utility.parseResponse(res);
    let onNewLayer: boolean = false;
    let onRemoval: boolean = false;
    let isResponseTextEnded = false;
    if (splitedByLineEnd.length > 0) {
      //if current state is menu work area fire that event.
      (splitedByLineEnd.find(x => x.code == '110')) ?
        (this._stateIsMenuWorkArea ? this._stateIsMenuWorkArea(splitedByLineEnd) : false) :
        false;
      //IF Quick Functions Exist
      if (splitedByLineEnd.find(x => x.code == '111')) this._compileQuickFunctions ? this._compileQuickFunctions() : false;
      //if FileUpload Exist Do open dialouge from here
      if (splitedByLineEnd.find(x => x.code == '121')) {
        let tempId = -1;
        if (splitedByLineEnd.find(x => x.code == '100')) {
          splitedByLineEnd.forEach(sl => {
            if (sl.code == '100') {
              let waObj = sl.value.split('|');
              let waAction = waObj[0];
              if (waAction == '0') {
                let samWARemovalExist = splitedByLineEnd.find(x => x.code == '100' && (x.value + '').startsWith('1') && +(((x.value + '')[2]).trim()) == +(waObj[1]));
                if (samWARemovalExist == undefined) {
                  tempId = waObj[1];
                }
              }
            }
          })
        }
        let elem = splitedByLineEnd.find(x => x.code == '121')
        if (tempId != -1) {
          this._uploadDialouge ? this._uploadDialouge({ id: tempId, res: elem.value }) : null;
          return;
        } else {
          this._uploadDialouge ? this._uploadDialouge({ id: 1000, res: elem.value }) : null;
        }
      }
      //if current workarea is dashboard
      if (splitedByLineEnd.find(x => x.code == '132')) this._redirectToDashboardComponent ? this._redirectToDashboardComponent() : false;
    }

    // picking last screen when receives multiple screen in response - Start
    // let lastIndex;
    // for (let i = 0; i < splitedByLineEnd.length; i++) {
    //   if (splitedByLineEnd[i].code == '100') {
    //     lastIndex = i
    //   }
    // }
    // splitedByLineEnd.splice(0, lastIndex - 1);
    // picking last screen when receives multiple screen in response - End

    for (let i = 0; i < splitedByLineEnd.length; i++) {
      let element = splitedByLineEnd[i];
      if (element.code == '001') {
        this._lastPerformedAction = undefined;
      } else if (element.code == '003') {
        //Session Expired
        let obj = element.value.split('|');
        let temObj = {
          _title: obj[2],
          body: obj[3],
          buttons: []
        }
        temObj.buttons.push({ text: obj[4], cmd: '0', lowercaseText: 'ok', isSessionExpired: true });
        temObj.buttons.push({ text: 'Reload Page', cmd: '0', lowercaseText: 'reload page', isSessionExpired: true })
        this._showMsgBox(temObj);
        break;
      }
      else if (element.code == '100') {
        let waObj = element.value.split('|');
        let waAction = waObj[0];
        switch (waAction) {

          case '0': {
            if (this._workAreaId) {
              if (this._controls.length != 0 || this._grid_columnsToDisplay.length != 0) {
                this._isActive = false;
                this._addNewLayer(splitedByLineEnd.slice(i, splitedByLineEnd.length));
                onNewLayer = true;
              } else {
                //if the new workarea id there but right after there id removal command exist for that workarea
                let samWARemovalExist = splitedByLineEnd.find(x => x.code == '100' && (x.value + '').startsWith('1') && +(((x.value + '')[2]).trim()) == +(waObj[1]))
                if (samWARemovalExist == undefined) {
                  this._title = waObj[6];
                  this._workAreaId = waObj[1];
                  this._panelWidth = this.getPanelWidth(waObj[3], waObj[5], waObj[2], waObj[4]);
                  this._isActive = true;
                  this._controls = [];
                  this._buttons = [];
                  this._isOptionSelectionScreen = false;
                  this._isGemPad = false;
                  this._isMenuWorkArea = false;
                  this._gridContextMenus = [];
                  this._gridContextMenuLoaded = false;
                } else {
                  this._isActive = false;
                  this._addNewLayer(splitedByLineEnd.slice(i, splitedByLineEnd.length));
                  onNewLayer = true;
                }
              }

            } else {
              if (onRenderNewLayer) {
                this._title = waObj[6];
                this._workAreaId = waObj[1];
                this._panelWidth = this.getPanelWidth(waObj[3], waObj[5], waObj[2], waObj[4]);
                this._isActive = true;
                this._controls = [];
                this._buttons = [];
                this._isGemPad = false;
                this._isMenuWorkArea = false;
                this._isOptionSelectionScreen = false;
                this._gridContextMenus = [];
                this._gridContextMenuLoaded = false;
              } else {
                this._isActive = false;
                this._addNewLayer(splitedByLineEnd.slice(i, splitedByLineEnd.length));
                onNewLayer = true;
              }
            }
            break;
          }
          case '1': {
            let removeWorkAreaId = waObj[1];
            if (removeWorkAreaId <= this._workAreaId) {
              this._isActive = false;
              this._removeLayer(splitedByLineEnd.slice(i + 1, splitedByLineEnd.length), removeWorkAreaId);
              onRemoval = true;
            } else {
              this._isActive == true;
            }
            break;
          }
          case '3': {
            this._title = waObj[2];
            // console.log("On Case 3::", waObj[2].split('\x7F'));
            if (!this._workAreaId) {
              this._workAreaId = waObj[1];
            }
            break;
          }
        }
        if (onNewLayer || onRemoval) {
          break;
        }
      } else if (element.code == '101' || element.code == '102') {
        let obj = element.value.split('|');
        let textval = obj[0].replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        let nRow = +(obj[1].substring(obj[1].indexOf('r') + 1, obj[1].indexOf('c')));
        let nCol = obj[1].substring(obj[1].indexOf('c') + 1);
        let cType = element.code == '101' ? 'label' : 'input';
        let em_width_Adjustment = 0.56;
        let elWidth = cType == 'input' ? ((((+obj[2]) * em_width_Adjustment) + 0.5 + 2).toFixed(4) + 'em') : 'auto';
        let itemIndex = this._controls.findIndex(x => x.nRow == nRow);
        if (itemIndex !== -1) {
          this._controls[itemIndex].elements.push({
            cType: cType, cValue: textval, cCol: nCol, isLookUp: obj[3] == '1000' ? true : false, focus: false,
            elLength: obj[2], endPosition: ((+nCol) + (+obj[2])), elWidth, elId: obj[5], isClickable: obj[4] == 1 ? true : false,
            extraParam: obj[2] + '|' + obj[3] + '|' + obj[4] + '|' + obj[5]
          });
        } else {
          this._controls.push({
            nRow: nRow,
            elements: [{
              cType: cType, cValue: textval, cCol: nCol, isLookUp: obj[3] == '1000' ? true : false, focus: false,
              elLength: obj[2], endPosition: ((+nCol) + (+obj[2])), elWidth, elId: obj[5], isClickable: obj[4] == 1 ? true : false,
              extraParam: obj[2] + '|' + obj[3] + '|' + obj[4] + '|' + obj[5]
            }]
          });
        }
      } else if (element.code == '103') {
        let pBtnArray = element.value.split('|');
        if (pBtnArray[4] == '0') { //Type ==Buttons
          let tempBtns = {
            label: pBtnArray[0],
            row: pBtnArray[1],
            column: pBtnArray[2],
            id: pBtnArray[3],
            lowercaseText: (pBtnArray[0] + '').trim().toLocaleLowerCase()
          };
          let btnRIndex = this._buttons.findIndex(x => x.row == tempBtns.row && x.column == tempBtns.column);
          //if index is not found means same button not exist other wise  already exisst no need to push
          if (btnRIndex == -1) {
            this._buttons.push(tempBtns);
            this._buttons.sort(function (a, b) {
              let a1 = a.column, b1 = b.column;
              if (a1 == b1)
                return 0;
              return a1 > b1 ? 1 : -1;
            });
          }

        } else {
          // Added radio button controls
          // add_xml_control("radio", "r" + pBtnArray[1] + "c" + pBtnArray[2], pBtnArray[0], pBtnArray[0].length, pBtnArray[5],	pBtnArray[5], pBtnArray[3], pBtnArray[4]);
          // isRadio = true;
        }

      } else if (element.code == '104') {
        let tempGrid = this.v1Utility.ParseGrid(element.value);
        let v3Config = this.v1Utility.GetV3FormatedGrid(tempGrid, this._GridClickableColName, this._enableClickableCol);
        // console.log("[[[********With in form Get this Grid********]]]", tempGrid, v3Config);
        this._grid_columnsToDisplay = [];
        this._grid_Items = [];
        this._isSearchingInputExist = false;
        this._gridProps = {
          visible_rows: tempGrid.rows,
          allow_search: tempGrid.search,
          enable_multiSelect: tempGrid.mode,
          available_more_rows: tempGrid.more
        }
        //move this setTimeOut in grid component
        /* setTimeout(() => {
           this._grid_columnsToDisplay=v3Config.cols;
           this._grid_Items=v3Config.data;
         }, 0); */

        //if only one blank row Exist in data, remove it
        let rowContainData: boolean = true;
        if (v3Config.data.length == 1) {
          let temRowObj = v3Config.data[0]; delete temRowObj["index"];
          const isEmpty = !Object.values(v3Config.data[0]).some(x => (x + '').trim() !== '');
          if (isEmpty) {
            v3Config.data = [];
          } else {
            v3Config.data[0]["index"] = 0;
          }
        }

        this._grid_columnsToDisplay = v3Config.cols;
        this._grid_Items = v3Config.data;
      } else if (element.code == '105') {
        let tempRowsMore = this.v1Utility.FastScanMore(element.value);
        this._gridProps.available_more_rows = tempRowsMore.moreRows;
        let v3Config = this.v1Utility.GetV3FormatedMoreRows(tempRowsMore.rowsData, this._grid_columnsToDisplay);
        // console.log("((((((***More Rows***)))))))", tempRowsMore, v3Config);
        this._grid_Items = this._grid_Items.concat(v3Config)

      } else if (element.code == '106') {
        let k1 = element.value.split('|');
        switch (k1[0]) {
          // Delete all Rows
          case '0':
            // console.log('Delete All');
            this._grid_Items = [];
            break;
          case '8':
            this._returnMultipleSelection ? this._returnMultipleSelection() : false;
            break;
        }
      }
      else if (element.code == '107') {
        let k1 = element.value;
        if (k1 != '-1') {
          let tnRow = k1.substring(k1.indexOf('r') + 1, k1.indexOf('c'));
          let tnCol = k1.substring(k1.indexOf('c') + 1);
          removeControls.push({ r: tnRow, c: tnCol, id: k1 });
          //Removing Control From Renderd Control
          let rIndex = this._controls.findIndex(x => x.nRow == tnRow);
          if (rIndex !== -1) {
            let cIndex = this._controls[rIndex].elements.findIndex(y => y.cCol == tnCol);
            if (cIndex !== -1) {
              this._controls[rIndex].elements.splice(cIndex, 1);
              //controls[rIndex].elements[cIndex]={};
            }
          }
          let btnRIndex = this._buttons.findIndex(x => x.row == tnRow && x.column == tnCol);
          if (btnRIndex !== -1) {
            this._buttons.splice(btnRIndex, 1);
          }
        }
        else {
          let tempForm = splitedByLineEnd.slice(i).find(r => r.code == '100');
          if (tempForm) {
            let tempWaObj = tempForm.value.split('|');
            let tempWaAction = tempWaObj[0];
            if (tempWaAction != '1') {
              this._controls = [];
              this._grid_Items = [];
              this._grid_columnsToDisplay = [];
              this._isSearchingInputExist = false;
              this._buttons = [];
              this._gridContextMenuLoaded = false;
              this._gridContextMenus = [];
            }
          } else {
            this._controls = [];
            this._grid_Items = [];
            this._grid_columnsToDisplay = [];
            this._isSearchingInputExist = false;
            this._buttons = [];
            this._isMenuWorkArea = false;
            this._gridContextMenus = [];
            this._gridContextMenuLoaded = false;
          }
        }
      } else if (element.code == '108') {
        focusControl = element.value;
      } else if (element.code == '109') {
        let tempItem = element.value.split('|');
        let temObj = {
          _title: tempItem[2],
          body: tempItem[3],
          buttons: []
        }
        if (tempItem[0] == '0') {//Standard MSg Box
          temObj.buttons.push({ text: tempItem[4], cmd: '0', lowercaseText: (tempItem[4] + '').toLocaleLowerCase() })
        } else if (tempItem[0] == '1') { //Multiple button msg box
          for (let b = 4; b < tempItem.length; b++) {
            temObj.buttons.push({ text: tempItem[b], cmd: tempItem[0] + '' + tempItem[1] + ';' + tempItem[b], lowercaseText: (tempItem[b] + '').toLocaleLowerCase() })
          }
        }

        if (this.__notifyOnProcessEnd_ofType && this.__notifyOnProcessEnd_ofType == 'MsgBoxConfirmation') {
          if (temObj.buttons.length > 1) {
            this._lastPerformedAction = 'MsgBoxConfirmation';
          }
        }
        this._showMsgBox(temObj);
        break;
      } else if (element.code == '110') {
        this._isMenuWorkArea = true;
      } else if (element.code == '116') {
        this.v1Utility.open_window(this.v1Utility._getEnvUrl
          + element.value, element.value);
      }
      else if (element.code == '117') {
        //Executed/Moved this V1 line of Code in command 999 code block below
        // let act=  parseInt(element.value.substring(0,1));
        // if (act == 1) {
        // 	// Removed the timeout since the calls are now sync
        //    get_data("[RAW]0\012");
        // }
        let requestedUrl = element.value.substring(1);
        this._print_request ? this._print_request(requestedUrl) : false;

      } else if (element.code == '121') {
        // this._uploadDialouge?this._uploadDialouge(element.value):null;
        // break;
      }
      else if (element.code == '126') {
        let tempItem = element.value.split('|');
        this._isGemPad = true;
        this._gemPadConfig = {
          send_response: +tempItem[4], // 0=modeless, 1=response (send "[RAW]0" to complete)
          readonly: +tempItem[5], // 0=write, 1=readonly
          diary_mode: +tempItem[6], // 0=do nothing, add string + 2 EOLs at the top
          optional_diary_string: tempItem[7], // diary string to add
          filename: tempItem[8],// file name
          fileContent: ''
        }
        this._loadGemPadContent ? this._loadGemPadContent() : false;
        break;
      }
      else if (element.code == '127') {
        this._gridContextMenus = [];
        let tempItm = element.value.split('|');
        //First Item is Title thats why we are removing it from action list
        tempItm.shift();
        tempItm.forEach(ctxEl => {
          let tempStr = (ctxEl + '').trim();
          if (tempStr == '*' || tempStr == '~') {
            this._gridContextMenus.push({ type: 'lineBreak' });
          } else {
            this._gridContextMenus.push({ type: 'menu', cmd: '[CTMENU]' + tempStr, text: tempStr });
          }
        });
        if (this._gridContextMenus && this._gridContextMenus.length > 0) {
          return;
        }
      } else if (element.code == '134') {
        let filename = element.value;
        let is_safari = navigator.userAgent.indexOf("Safari") > -1;
        let is_chrome = navigator.userAgent.indexOf("Chrome") > -1;

        window.open(this.v1Utility._getEnvUrl + '/storage/files/' + filename, '_download');
        this._fileDownloadedCompleted ? this._fileDownloadedCompleted() : false;
        return;
        // let a = document.createElement('a');
        // document.body.appendChild(a);
        // a.setAttribute('style', 'display: none');
        // a.href = filename;
        // a.download = filename;
        // a.click(()=>{
        //   window.open('/gemxtmp/pictures/upload/' + filename, '_download');
        // });
        // a.click(function(){
        //   window.open('/gemxtmp/pictures/upload/' + filename, '_download');
        // });
        // a.remove(); // remove the element

      }
      else if (element.code == '999') {
        if (this._grid_columnsToDisplay.length > 0 && this._controls.length == 1 && this._controls[0].elements.length > 1) {
          let tempLabel = (this._controls[0].elements[0].cValue + '').trim().toLowerCase();
          if (tempLabel == 'request' && this._controls[0].elements[1].cType == 'input') {
            this._controls = [];
            this._isSearchingInputExist = true;
          }
        }
        isResponseTextEnded = true;
        if (this._controls.length <= 1 && this._grid_columnsToDisplay.length > 0 && this._grid_Items.length == 0 && onRenderNewLayer) {
          this._autoRequestOnEmptyGrid ? this._autoRequestOnEmptyGrid() : false;
          break;
        }

        /*On Print-Command 117 if we get 1st param value=1 (param after command)
        we had to execute below code in v1 but here we are executing it in this block because
        In this block all above command are rendered completly and this block send anohter request
        whose response will be rendered then
        */
        let printCommandExist = splitedByLineEnd.find(x => x.code == '117');
        if (printCommandExist && parseInt(element.value.substring(0, 1)) == 1) {
          let act = parseInt(element.value.substring(0, 1));
          // Removed the timeout since the calls are now sync
          this._extraSyncRequestOnPrint ? this._extraSyncRequestOnPrint("[RAW]0\\012") : false;
        }
      }
    }
    //Sorting Controls Column Wise
    for (let s = 0; s < this._controls.length; s++) {
      this._controls[s].elements.sort(function (t, u) {
        return (+t.cCol) < (+u.cCol) ? -1 : (+t.cCol) > (+u.cCol) ? 1 : 0;
      });
    }
    //Set Focus To Control
    if (focusControl) {
      //First Remove focus if Any exist already.
      let focusExistAlready = this._controls.find(x => x.elements.find(y => y.focus == true) !== undefined);
      if (focusExistAlready) {
        let fEl = focusExistAlready.elements.find(y => y.focus == true);
        fEl.focus = false;
      }
      let fRow = focusControl.substring(focusControl.indexOf('r') + 1, focusControl.indexOf('c'));
      let fCol = focusControl.substring(focusControl.indexOf('c') + 1);
      for (let fe = 0; fe < this._controls.length; fe++) {
        if (fRow == this._controls[fe].nRow) {
          for (let ife = 0; ife < this._controls[fe].elements.length; ife++)
            if (this._controls[fe].elements[ife].cCol == fCol) {
              this._controls[fe].elements[ife].focus = true;
              if (this.__notifyOnProcessEnd_ofType && this.__notifyOnProcessEnd_ofType == 'ActionFromInputSubmission') {
                if (this._controls[fe].elements.length > 2 && (this._controls[fe].elements[0].cValue + '').trim() == 'Action :' && (this._controls[fe].elements[1].cValue + '').trim().includes('Data Okay')) {
                  this._lastPerformedAction = 'ActionFromInputSubmission';
                } else {
                  this._lastPerformedAction = undefined;
                }
              }
            }
        }
        //if we have any lookup field but ,focus is on another field, we will change that field from lookup to input
        let notFocusedLookupFieldExist = this._controls[fe].elements.find(y => y.isLookUp == true && y.focus == false);
        if (notFocusedLookupFieldExist) {
          notFocusedLookupFieldExist.isLookUp = false;
        }
      }
      //if we have any lookup field but ,focus is on another field, we will change that field from lookup to input
      // let notFocusedLookupFieldExist = this._controls.find(x => x.elements.find(y => y.isLookUp == true && y.focus == false) !== undefined);
      // if (notFocusedLookupFieldExist) {
      //   let elem = notFocusedLookupFieldExist.elements.find(y => y.isLookUp == true && y.focus == false);
      //   elem.isLookUp = false;
      // }
    }
    //Sorting Controls Row Wise
    this._controls.sort(function (a, b) {
      return a.nRow < b.nRow ? -1 : a.nRow > b.nRow ? 1 : 0;
    });

    this.CheckifScreenIsOption();
    this._AllignItems();
    let tempArry = this._controlElements.length > 1 ?
      this._controlElements[0].concat(this._controlElements[1]) :
      this._controlElements[0];
    this._controlElementsTemp = [];
    this._controlElementsTemp.push(tempArry);
    isResponseTextEnded ? (this._onComplition ? this._onComplition(Object.assign([], splitedByLineEnd)) : false) : false;
    this.__onComplition.emit();
  }
  //Array of sorted Input/Labels binding object in different json hierarchy
  _layoutElements = [];
  //this variable holds action row (action row is a confirmation row that we see in bootom of the form in old v1 also here in this app)
  //that is splitted from main entryForm object
  _actionConfirmationRow = [];
  //Set maximu input label width
  _maxInputLabelWidth = 20;
  /**
   * This method create v3 Entry From controls displaying hierarchy of elements in json format, from  old v1 hierarchy
   * @param elGroup v1 hierarchy object
   * @returns new hierarchy as per v3-standared
   */
  _CreateNewElementObjectLayout(elGroup) {
    //Old to New group element Object
    let newObj = Object.assign({}, elGroup);
    newObj.elements = [];
    elGroup.elements.forEach(el => {
      if (el.length == 1) {
        newObj.elements.push({
          elType: 'Single',
          el
        });
      } else {
        let labelsOnly = ((el.find(x => x.cType == 'input')) == undefined);
        el.forEach((elLbl, elLablIndx) => {
          if (elLablIndx < el.length - 1) {
            let diff = ((+el[elLablIndx + 1].cCol) - (elLbl.endPosition));
            diff == 0 ? (diff = 10) : (diff == 1 ? (diff = 15) : (diff = diff * 10))
            el[elLablIndx]['_paddRight'] = diff + 'px'
          } else {
            el[elLablIndx]['_paddRight'] = "0px";
          }
        });
        if (labelsOnly) {
          newObj.elements.push({
            elType: 'LabelsOnly',
            el
          });
        } else {
          if (el.length > 1 && el.length < 4) {
            let inputIndex = el.findIndex(x => x.cType == 'input');
            let firstPart = el.slice(0, inputIndex);
            let SecondPart = el.slice(inputIndex);
            newObj.elements.push({
              elType: 'LabelInputGroup',
              el: [firstPart, SecondPart]
            });

            let tempWidth = 0;
            firstPart.forEach((itm) => {
              tempWidth = tempWidth + (this.getTextWidth(itm.cValue));
            });
            if (this._maxInputLabelWidth < tempWidth) {
              this._maxInputLabelWidth = tempWidth;
            }
          } else {
            for (let i = 2; i <= el.length; i += 2) {
              let tempSection = [];
              if (i + 1 == el.length) {
                tempSection = el.slice(i - 2);
                let secndPrt = tempSection.slice(1, tempSection.length)
                newObj.elements.push({
                  elType: 'LabelInputGroup',
                  el: [[tempSection[0]], secndPrt]
                });
              } else {
                tempSection = el.slice(i - 2, i);
                newObj.elements.push({
                  elType: 'LabelInputGroup',
                  el: [[tempSection[0]], [tempSection[1]]]
                });
              }
              let tempWidth = (this.getTextWidth(tempSection[0].cValue));
              if (this._maxInputLabelWidth < tempWidth) {
                this._maxInputLabelWidth = tempWidth;
              }
            }
          }
        }

      }
    });
    return newObj;
  }
  /**
   * This method generate single column base layout from multiple coulmn layout as like old v1 Entry-screens multiple column layout
   * @param layoutControls  object of controls
   * @returns
   */
  MergeNewLayoutColumnsIntoOne(layoutControls: any[]) {
    let tempArry = [];
    //remove Action Cconfirmation row From argument first and move to anotheravariable
    if (layoutControls[0].length > 2) {
      let actionRowExist: boolean = false;
      let messageRowExist: boolean = false;
      let tempActionRow = layoutControls[0][layoutControls[0].length - 2].elements;
      if (tempActionRow.length == 1 &&
        ((tempActionRow[0].elType != 'LabelInputGroup' && (tempActionRow[0].el[0].cValue + '').startsWith('Action'))
          || (tempActionRow[0].elType == 'LabelInputGroup' && tempActionRow[0].el[0].length > 0 && (tempActionRow[0].el[0][0].cValue + '').startsWith('Action')))) {
        actionRowExist = true;
      }
      let tempMsgRow = layoutControls[0][layoutControls[0].length - 1].elements;
      if (tempMsgRow.length == 1 && (tempMsgRow[0].el[0].cValue + '').startsWith('Message')) {
        messageRowExist = true;
      }
      if (actionRowExist) {
        tempActionRow = layoutControls[0].splice(layoutControls[0].length - 2, 1);
        if (tempActionRow) {
          this._actionConfirmationRow.push([])
          for (let e = 0; e < tempActionRow[0].elements[0].el.length; e++) {
            this._actionConfirmationRow[0] = this._actionConfirmationRow[0].concat(tempActionRow[0].elements[0].el[e])
          }

          if (messageRowExist) {
            tempMsgRow = layoutControls[0].splice(layoutControls[0].length - 1, 1);
            if (tempMsgRow) {
              this._actionConfirmationRow.push([])
              for (let e = 0; e < tempMsgRow[0].elements[0].el.length; e++) {
                this._actionConfirmationRow[1] = this._actionConfirmationRow[1].concat(tempMsgRow[0].elements[0].el[e])
              }
            }
          }
        }
      }
      // console.log("Action Row Compiled", this._actionConfirmationRow);
    }
    if (layoutControls.length > 1) {
      //In Oder Entry Last Step in line Item Entry Form , there are 2 labels in one row for hint ,
      //In our calculation we are geeting last label in 2nd column thats why for combining this lable we are useing this check
      let secondColContainOneLabelOnly = (layoutControls[1].length == 1 && layoutControls[1][0].elements.length == 1
        && layoutControls[1][0].elements[0].elType == 'Single');
      let frstColLastItemIsLabel = (layoutControls[0][layoutControls[0].length - 1].elements.length == 1
        && layoutControls[0][layoutControls[0].length - 1].elements[0].elType == 'Single');
      if (secondColContainOneLabelOnly && frstColLastItemIsLabel) {
        layoutControls[0][layoutControls[0].length - 1].elements[0].elType = 'LabelsOnly';
        layoutControls[1][0].elements[0].el[0]['_paddLeft'] = '25px';
        layoutControls[0][layoutControls[0].length - 1].elements[0].el.push(
          layoutControls[1][0].elements[0].el[0]
        );
        tempArry = layoutControls[0];
      } else {
        tempArry = layoutControls[0].concat(layoutControls[1]);
      }
    } else {
      tempArry = layoutControls[0];
    }
    let grouping = [[]];
    tempArry.forEach((item, index) => {
      grouping[grouping.length - 1].push(item);
      if (index < tempArry.length - 1) {
        if ((tempArry[index + 1].nRow - item.nRow) > 1) {
          grouping.push([]);
        }
      }
    })
    this._maxInputLabelWidth = Math.ceil(this._maxInputLabelWidth);
    return grouping;
  }
  /**
   * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
   *
   * @param {String} text The text to be rendered.
   * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
   *
   */
  private canvasObj;
  private getTextWidth(text) {
    let font = 'bold 12px lato';
    // re-use canvas object for better performance
    let canvas = this.canvasObj || (this.canvasObj = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText((text + '').trim());
    return metrics.width;
  }
  /**
   * This function create different type of v3-style UI hierarchy for Entry Screens and save them on binding object
   */
  _AllignItems() {
    //before Ugrading to new Design Rules
    this._controlElements = [];
    //array with new design Rules
    this._layoutElements = [];
    this._actionConfirmationRow = [];
    let tempNewLayoutBasedControlElement = [];

    this._controlElements.push([]);
    tempNewLayoutBasedControlElement.push([]);

    for (let i = 0; i < this._controls.length; i++) {
      //Partition of row element if there are more then one columns in row or more then one gruoped element in a row
      let divisionObj = this.Partitions(this._controls[i].elements);
      if (divisionObj.length > 0) {
        // create new object of the row object that icludes all row element
        let temElementObj = Object.assign({}, this._controls[i]);
        // let temControl:[]=Object.assign([], this._controls[i].elements);
        //create partition of elements in the group, partition can contains:
        // ( LI, LIL, LLI, L, I ,Ln   --> (Input=I/Lable=L/Labels=Ln)
        // this is creating partition of 1st group that we already calculated in  {divisionObj} above
        //in {divisionObj} we have the  index number from where 2nd group is started
        let partObj = this.PartitionsintoCols(this._controls[i].elements.slice(0, divisionObj[0].SIndex));
        temElementObj.elements = partObj.cols;
        // push that 1st grup of element into main controls array and handle the rest gruop below in foreeach
        this._controlElements[0].push(temElementObj);
        let tempNewElObj = Object.assign({}, temElementObj);
        tempNewElObj.elements = partObj.newFormat;
        tempNewLayoutBasedControlElement[0].push(this._CreateNewElementObjectLayout(tempNewElObj));

        //here we handle the furter groups of element
        divisionObj.forEach((element, dIndex) => {
          let temEleObj = Object.assign({}, this._controls[i]);
          //here we are creating partition of further groups, and grups are now get by last index plus 1, because slice method last index is +1
          let pObj = this.PartitionsintoCols(this._controls[i].elements.slice(element.SIndex, element.LIndex));
          temEleObj.elements = pObj.cols;
          if (this._controlElements.length <= dIndex + 1) {
            this._controlElements.push([]);
            tempNewLayoutBasedControlElement.push([]);
          }
          this._controlElements[dIndex + 1].push(temEleObj);
          let tempNewElObj = Object.assign({}, temEleObj);
          tempNewElObj.elements = pObj.newFormat;
          tempNewLayoutBasedControlElement[dIndex + 1].push(this._CreateNewElementObjectLayout(tempNewElObj));
        });
      } else {
        let temElementObj = Object.assign({}, this._controls[i]);
        //create partition of elements in the group, partition can contains:
        // ( LI, LIL, LLI, L, I ,Ln   --> (Input=I/Lable=L/Labels=Ln)
        let partObj = this.PartitionsintoCols(this._controls[i].elements);
        temElementObj.elements = partObj.cols;
        this._controlElements[0].push(temElementObj);
        let tempNewElObj = Object.assign({}, temElementObj);
        tempNewElObj.elements = partObj.newFormat;
        tempNewLayoutBasedControlElement[0].push(this._CreateNewElementObjectLayout(tempNewElObj));
      }
    }
    if (tempNewLayoutBasedControlElement.length > 1) {
      //let secndColStart=+(this._controlElements[1][0].elements[0].elements[0].cCol);
      //Minium CCol point from where element is start
      let secndColStart;
      for (let ind = 0; ind < this._controlElements[1].length; ind++) {
        this._controlElements[1][ind].elements.forEach(elm => {
          let tempCol = +(elm.elements[0].cCol);
          secndColStart = secndColStart ?
            tempCol < secndColStart ? tempCol : secndColStart
            : tempCol;
        });
      }

      // console.log("SEcond COl Mini Point****)))", secndColStart)
      let seprateSecndColElem = Object.assign([], (this._controlElements[0].filter(x => x.elements.length > 0 && (+(x.elements[0].elements[0].cCol) >= secndColStart)).map(itm => {
        return itm.nRow
      })));
      if (seprateSecndColElem.length > 0) {
        seprateSecndColElem.forEach(nR => {
          let indx = tempNewLayoutBasedControlElement[0].findIndex(x => x.nRow == nR);
          if (indx !== -1) {
            tempNewLayoutBasedControlElement[1] = tempNewLayoutBasedControlElement[1].concat(tempNewLayoutBasedControlElement[0].splice(indx, 1));
          }
        });
        tempNewLayoutBasedControlElement[1].sort(function (a, b) {
          return a.nRow < b.nRow ? -1 : a.nRow > b.nRow ? 1 : 0;
        });
      }
    }
    //this.eachColSize=Math.ceil(12/this._controlElements.length);

    this._layoutElements = this.MergeNewLayoutColumnsIntoOne(tempNewLayoutBasedControlElement);
    // console.log("(((((((((*******After Aligning Items*********))))))))"
      // , this._controls, tempNewLayoutBasedControlElement);
  }
  /**
   * This function clear out workarea configrations and UI elemetns bindings
   */
  _clearControls() {
    this._workAreaId = undefined;
    this._isActive = false;
    this._title = undefined;
    this._controls = [];
    this._controlElements = [];
    this._grid_Items = [];
    this._grid_columnsToDisplay = [];
    this._isSearchingInputExist = false;
    this._panelWidth = undefined;
    this._gridProps = {
      visible_rows: 0,
      allow_search: true,
      enable_multiSelect: true,
      available_more_rows: false
    }
    this._gridContextMenuLoaded = false;
    this._gridContextMenus = [];
  }
  /**
   *
   * @returns Get label/Input group wise controls
   */
  getRawControls() {
    let tempStateAsString = '';
    this._controls.forEach(elRow => {
      elRow.elements.forEach(elControls => {
        tempStateAsString = tempStateAsString + (elControls.cType + elControls.cValue);
      });
    });
    return tempStateAsString;
  }
  /**
   * Get Label value
   * @param label
   */
  getControlValue(label) {
    this._controls.forEach(elRow => {
      if (elRow.elements.length > 1 && elRow.elements[0].cType == 'label') {
        let val = (elRow.elements[0].cValue + '').trim();
        if (val.endsWith(label)) {
          return elRow.elements[1].cValue;
        }
      }
    });
  }
  /**
   *
   * @param elements list of all elements in a row
   * @returns make partition of rows on the bases of design Algorithm implimented inside this function only
   */
  private Partitions(elements) {
    let divisionObj = []
    elements.forEach((element, index) => {
      //only iterate till 2nd last item
      if (index + 1 < elements.length) {
        // get space difference b/w element with next element
        let difference = (+elements[index + 1].cCol) - ((+element.cCol) + (+element.elLength));
        if (difference != 1 && difference > 6) {
          if (divisionObj.length > 0) {
            divisionObj[divisionObj.length - 1].LIndex = index + 1;
          }
          divisionObj.push({ SIndex: index + 1, LIndex: elements.length });
        }
      }
    });
    //console.log("Devision Object:",divisionObj);
    return divisionObj;
  }
  /**
   * Partition of row groups in v3 style layout
   * @param elements row Elements
   * @returns
   */
  private PartitionsintoCols(elements) {
    let cols = [];
    let newFormat = [];
    //calculate avg for further decision making of per element group's bootstrap based col size
    let avg = 120 / (elements.length > 0 ? (elements[elements.length - 1].endPosition) : 1);
    elements.forEach((el, i) => {
      if (i == 0) {
        cols.push({ colSize: this.getColSize(avg, el.endPosition), elements: [el] });
        newFormat.push([el]);
      } else {
        /*if cuurent element's poistion minus previous element position is equal to one , means its
        belong to one group of elements then combine this element with that group:: else push to another group*/
        let difference = ((+el.cCol) - elements[i - 1].endPosition);
        if (difference == 1) {
          //this updates bootstrap based col-size for this group , each time it adds new element into group
          //because col size is updated on the basis of last element's end position
          cols[cols.length - 1].colSize = this.getColSize(avg, el.endPosition);
          cols[cols.length - 1].elements.push(el);
          newFormat[newFormat.length - 1].push(el);
        } else {
          cols.push({ colSize: this.getColSize(avg, el.endPosition), elements: [el] });
          if (difference < 5) {
            newFormat[newFormat.length - 1].push(el);
          } else {
            newFormat.push([el]);
          }
        }
      }
    });
    return { cols, newFormat };
  }
  //Get bootstrap col size for each Group controls
  private getColSize(avg, elPos) {
    //let col='col-'+Math.round((avg*elPos)/10);
    let col = 'col';
    return col;
  }
  //Claculate Workarea width hight from 100 command response arguments
  private getPanelWidth(leftCol, rightCol, topCol, bottomCol) {
    let totalColPoints = 160;
    let totalRowPoints = 26;
    let calculatedWidth = (+rightCol) - (+leftCol);
    let calculatedHeight = (+bottomCol) - (+topCol);
    let totalscreenWidth = window.screen.width;
    let totalScreenHeight = window.screen.height;

    let widthInpx = Math.round((totalscreenWidth / totalColPoints) * calculatedWidth);
    let widthInpercnt = Math.round((widthInpx) / (totalscreenWidth / 100));

    let heightInPx = Math.round((totalScreenHeight / totalRowPoints) * calculatedHeight);
    let heightInPercnt = Math.round((heightInPx) / (totalScreenHeight / 100));

    // console.log("Width in px and percent:", widthInpx + 'px', widthInpercnt + '%');
    // console.log("Height in px and percent:", heightInPx + 'px', heightInPercnt + '%');
    return widthInpx + 'px';

  }
  //Calculate if current workarea is type of option selection screen
  CheckifScreenIsOption() {
    if (this._grid_columnsToDisplay.length == 0 && this._controls.length > 0) { //&& (this._title+'').toLowerCase().includes('options')
      let allOptions = true;
      for (let c = 0; c < this._controls.length - 1; c++) {
        if (!(this._controls[c].elements.length == 1 && this._controls[c].elements[0].cType == 'label')) {
          allOptions = false;
        }
      }
      if (allOptions == true && this._controls[this._controls.length - 1].elements.length > 0
        && this._controls[this._controls.length - 1].elements[0].cType == 'label'
        && (this._controls[this._controls.length - 1].elements[0].cValue + '').trim() == 'Selection') {
        this._isOptionSelectionScreen = true;
      }
    }
  }
  //Calculate if response contains workarea that is not containg any controls
  isNotAppearableWorkArea(splitedByLine: clsv1Response[]) {
    let newWorkAreaId = splitedByLine[0].value.split('|')[1];
    let index = splitedByLine.findIndex(x => x.code == '100' && (x.value + '').startsWith('1') && +(((x.value + '')[2]).trim()) == +(newWorkAreaId));
    if (index !== -1) {
      let sect = splitedByLine.slice(0, index);
      let urlLine = sect.filter(x => x.code == '117' || x.code == '116');
      return { index: index, urlLine: urlLine }
    } else {
      return { index: -1, urlLine: undefined }
    }
  }
  //IF skipped Not Appreable workarea then ensure no to skipp 117 command
  anyURLExistInSkipedLines(skippedLines: clsv1Response[]) {
    skippedLines.findIndex(x => x.code == '117')
  }
}
