import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, clsv1Response, UtilityService, V1MenuService, V1UtilitiesService } from '@app/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})


export class FileUploadComponent implements OnInit,OnDestroy {
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  successMsg;
  uniqueid;
  filemask;
  title;
  waId=1000;
  pathfilename='C:\\';
  ESCkeySubscription;
  constructor(public dialogRef: MatDialogRef<FileUploadComponent>,private v1Utility:V1UtilitiesService,
    private auth:AuthenticationService,
    private _utilityService:UtilityService,public v1menuservice:V1MenuService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      if(data){
        this.ESCkeySubscription= this._utilityService.onEscapeKeyPress.subscribe(x=>{
          this.exitDialouge();
        });
        this.waId=data.res.id;
        let chunks:[any]= data.res.split('|');
        if(chunks.length>0){
          this.uniqueid = chunks[0];
          var filemasks = []; // file masks as array of possible file mask
          // options

          for (let k = 1; k < chunks.length - 3; k++) {
            filemasks.push(chunks[k]);
          };
          this.filemask=filemasks.join(',');
          this.title= chunks[chunks.length - 3];
          //var buttonlabel = pMsgArray[pMsgArray.length - 2];
			    let _pathfilename = chunks[chunks.length - 1];
          if(_pathfilename && _pathfilename.length>0) this.pathfilename=_pathfilename;
        }else{
          this.exitDialouge();
        }
      }else{
        this.exitDialouge();
      }

     }
    ngOnDestroy(): void {
      this.ESCkeySubscription.unsubscribe();
    }
    ngOnInit(): void {
    }
    selectFile(event): void {
      this.selectedFiles = event.target.files;
    }

    upload(): void {
      this.progress = 0;
      this.currentFile = this.selectedFiles.item(0);
      this.v1Utility.upload(this.currentFile,this.uniqueid).subscribe(
        event => {
        let resChunks =event.split('|');
        //File Upoaded successfully
        if(resChunks[0]=='0'){
        setTimeout(() => {
          this.successMsg=resChunks[1];
          this.auth.gemxPost ({
            gconn: this.auth.gConn,
            gcmd: '[RAW]'+this.pathfilename+resChunks[2]
          }).subscribe(x=>{
            this.dialogRef.close(this.v1Utility.parseResponse(x));
          });
        }, 500);
        }else{
           //Something happend
           this.exitDialouge();
        }
        },
        err => {
          // console.log("Error From File Uploading",err);
          this.exitDialouge();
        });

      this.selectedFiles = undefined;

    }

    exitDialouge(){
      this.auth.gemxPost({
        gconn: this.auth.gConn,
        gcmd: '[RAW]'
      }).subscribe(x=>{
        let splited:clsv1Response[]=this.v1Utility.parseResponse(x);
        if(splited.find(x=>x.code=='109')){
           splited=splited.slice(splited.findIndex(y=>y.code=='109'),splited.length-1);
        }
        this.dialogRef.close(splited)
      })
    }
  }


