import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, clsSubMenu, V1MenuService, V1UtilitiesService } from '@app/core';
import { LoaderService } from '@app/core/services/loader.service';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.scss']
})
export class AdvanceFilterComponent implements OnInit {
  panelOpenState = false;
  filterObj:clsSubMenu[]=[];
  constructor(private auth:AuthenticationService,private router:Router,
    private v1Utility:V1UtilitiesService,public v1Menu:V1MenuService,private loaderSer:LoaderService) { }
  @Input('toggle') _opened=false;
  @Input('isLowerLevel') _inventoryManagement?=false
  @Output('toggleChange') _toggleChange=new EventEmitter<any>();
  @Output('onInquiryOpend') _onInquiryOpend=new EventEmitter<any>();

  ngOnInit(): void {
    let tempObj = Object.assign([],this.v1Menu.menu.getInquiriesMenuItems('lower'));
    tempObj.forEach(element => {
      element['toggleValue'] = false;
      element['menuLevel'] = 0;
    });
    this.filterObj=tempObj;
    // console.log("Filter Obj",this.filterObj);
  }

  LoadInquriesFromMainMenus(){
    let tempObj = Object.assign([],this.v1Menu.menu.getInquiriesMenuItems());
    tempObj.forEach(element => {
      element['toggleValue'] = false;
      element['menuLevel'] = 0;
    });
    this.filterObj=tempObj;
    // console.log("Filter Obj",this.filterObj);
  }

  inquiriesChanged(masterIndex,childIndex){
    if(this.filterObj[masterIndex].childs[childIndex]['relatedmenuLoaded']==undefined){
      //If we get Menus in last response
      if(this.isLastStateWasMenu()){
        //check menu called from new loaded menues or previous level menu
        let menuOfLevelCalled=+(this.filterObj[masterIndex]['menuLevel']);
        let menuOfLevelLast=+(this.filterObj[this.filterObj.length-1]['menuLevel']);
        //If requested from Previos Level
        if(menuOfLevelCalled<=menuOfLevelLast){
        this.Navigate_Maintain_State_andCallCMD(true,masterIndex,childIndex);
        }
        //If requested from newly loaded Level
        else{
         this.Navigate_Maintain_State_andCallCMD(false,masterIndex,childIndex);
        }
      }
      //IF we loaded screen in last response
      else{
        if (!this._inventoryManagement) {
          this.Navigate_Maintain_State_andCallCMD(true,masterIndex,childIndex);
        }
        else {
          this.Navigate_Maintain_State_andCallCMD(false,masterIndex,childIndex,true);
        }

      }
    }
  }
  Navigate_Maintain_State_andCallCMD(startFromMainMenu:boolean,reqMenuMasterIndex,reqMenuChildIndex,lowerLevelInquiries=false){
    //Clear previous active menu property
    this.clearMenuActiveProperty();
    //First Set new reequested menu active property so that navigationw will happen from main menu to target menu
    this.filterObj[reqMenuMasterIndex].setAsActive(reqMenuChildIndex);
    //NAvigateing back to Main menu and then caclled requested menu command
    if(startFromMainMenu){
      this.v1Menu.navigateToMenuSection(this.loaderSer.lastResponse).subscribe(x=>{
        let frwdPath=this.getFarwardNavigation();
        this.v1Menu.doNavigation(frwdPath).subscribe(y=>{
         let isLastResponseIsMenu= this.ParseResponse(y,frwdPath[frwdPath.length-1]);
         if(isLastResponseIsMenu){
            //now we will remove active class from menu that gave us other level of menu
            //and will add property to not reload again that level if user click on that specifi menu again
            this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex]['relatedmenuLoaded']=true;
            this.filterObj[reqMenuMasterIndex].setAsDeActive();
         }
        });
      });
    }
    //Direct call requested command
    else{
      if (lowerLevelInquiries){
        this.clearMenuActiveProperty();
        this.v1Menu.inquirycmdhistory=this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex].cmd.value;
          this.filterObj[reqMenuMasterIndex].setAsActive(reqMenuChildIndex);
          this.auth.gemxPost({
            gconn: this.auth.gConn,
            gcmd: '[RAW][ESC]'
          }).subscribe(res => {
            this.auth.gemxPost({
              gconn: this.auth.gConn,
              gcmd: '[MENU]'+this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex].cmd.value
            }).subscribe(res => {
              this.ParseResponse(res,this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex].cmd.value)
            })
          });
      }
      else {
        let frwdPath=this.getFarwardNavigation();
    //  let nvgPath=['[MENU]'+this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex].cmd.value];
      this.v1Menu.doNavigation(frwdPath).subscribe(y=>{
        let isLastResponseIsMenu= this.ParseResponse(y,frwdPath[0]);
        if(isLastResponseIsMenu){
           //now we will remove active class from menu that gave us other level of menu
           //and will add property to not reload again that level if user click on that specifi menu again
           this.filterObj[reqMenuMasterIndex].childs[reqMenuChildIndex]['relatedmenuLoaded']=true;
           this.filterObj[reqMenuMasterIndex].setAsDeActive();
        }
       });
      }
    }
  }
  ParseResponse(res,exicutedCommand){
    // console.log("Got Data",res);
    let splitedByLineEnd=this.v1Utility.parseResponse(res);
    //Got Menus
    if(splitedByLineEnd.find(x=>x.code=='110')){
      let v1Formated= this.v1Menu.V1FunctionForParsingMenus(splitedByLineEnd.find(x=>x.code=='110').value);
      let nextLevel=+(this.filterObj[this.filterObj.length-1]['menuLevel']) + 1;
      for(const item of v1Formated.sections){
        let tempMen=this.v1Menu.ConstructMenuObject(item);
        let tempMenuItems=tempMen.menus.filter(x=>x.title.includes('Inquiries'));

        //Convert clsMenu Item array to clsSubMenu array
        for(let i=0;i<tempMenuItems.length;i++){
          let temp:clsSubMenu=new clsSubMenu();
          temp.title=tempMenuItems[i].title;
          temp.tooltip=tempMenuItems[i].tooltip;
          ////If Redirect To Back Menu Option exist then remove it.
          temp.childs=this.removeMasterMenu(((this.router.url+'').trim().toLowerCase().includes('order')),tempMenuItems[i].SubMenues);
          // REMOVING NUMBERS FROM INQUIRY TITLES, IF EXIST
          temp.childs.forEach(child => {
            child.title = this.v1Menu.removeNumberFromText(child.title);
          })
          //Temprory Included for use of HTML and styling handling
          temp['toggleValue'] = false;
          temp['menuLevel'] = nextLevel;
          temp['ParentCmd']=exicutedCommand;
          this.filterObj.push(temp);
      }
      }
    return true;
    }
    //Got Grid Data
    else {
      this._onInquiryOpend.emit({splitedRes:splitedByLineEnd});
      this._opened=false;
      this._toggleChange.emit(this._opened);
      return false;
    }
  }

  getFarwardNavigation(){
    //Get Inquiries Menue default active complete path
    let frwNavArray=this.v1Menu.getForwardNavigationPath();
    //Filter Only Main Menu Request
    let frwdNav=frwNavArray.filter(f=>f.type=='request').map(x=>x.cmd);
    let mastrObj=this.filterObj.find(x=>x.isActive==true);
    let chldObj=mastrObj.childs.find(x=>x.isActive==true);
    if(mastrObj['menuLevel']==0){
      return [frwdNav,'[MENU]'+chldObj.cmd.value];
    }else{
      let prevLevelCmd=mastrObj['ParentCmd'];
      let levelWiseCmds=[frwdNav[0]];
      for(let i=1;i<=mastrObj['menuLevel'];i++){
        let levlCmd=this.filterObj.find(x=>x['menuLevel']==i)['ParentCmd'];
        levelWiseCmds.push(levlCmd);
      }
      levelWiseCmds.push(('[MENU]'+chldObj.cmd.value));
      return levelWiseCmds;
    }
  }
  removeMasterMenu(isOrder,SubMenues:clsSubMenu[]){
    let mainMenuObj=this.v1Menu.menu.menus[0].menu_mapping_Obj.menus.find(x=>x.parent_key==(isOrder?'orderShipment':'arnRecieptProcessing'));
    let menuIndex=SubMenues.findIndex(x=>(this.v1Menu.menu.menus[0].compareMenuTitle(mainMenuObj.title,x.title)))
    if(menuIndex!==-1){
      SubMenues.splice(menuIndex,1);
    }
    return SubMenues;
  }
  isLastStateWasMenu(){
    //If there is any menu active means we were on grid state
    //but if no menu is active means last time we got menus in response
    let activeExist=this.filterObj.find(x=>x.isActive);
    return activeExist?false:true;
  }
  get isOpenInquiriesActive(){
    return this.filterObj.some(op=>op.isActive===true && op.title.trim().toLowerCase().match(/open.+inquiries/))
  }
  clearMenuActiveProperty(){
    this.filterObj.forEach(x=>{
      x.setAsDeActive();
    })
  }

  onClose(){
    if(this.isLastStateWasMenu()){
      this.Navigate_Maintain_State_andCallCMD(true,0,0);
    }else{
      this._opened=false;
      this._toggleChange.emit(this._opened);
    }
  }
}
