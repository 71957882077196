import { Directive, Input, ElementRef, Renderer2, OnInit, HostListener, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[matSlideToggle]'
})
export class MatSlideToggleDirective implements OnInit, AfterViewChecked {

  activeLabel: string;
  inActiveLabel: string;

  @Input('activeLabel') set active(label: string) {
    this.activeLabel = label? label : 'Yes';
  }
  @Input('inActiveLabel') set inActive(label: string) {
    this.inActiveLabel = label? label : 'No';
  }

  // Element Change event listner to set the value of toggle with respect to its changed state
  @HostListener('change', ['$event']) toggleChange(event: any) {
    // console.log('change: ', event);
    if (this.isToogleActive()) {
      this.setInActiveValue();
    } else {
      this.setActiveValue();
    }
  }

  // el is the element reference on which directive is added
  // _renderer is an object to manupulate the properties of an element
  constructor(private el: ElementRef, private _renderer: Renderer2) {
  }

  ngOnInit() {
    this.activeLabel = this.activeLabel? this.activeLabel : 'Yes';
    this.inActiveLabel = this.inActiveLabel? this.inActiveLabel : 'No';
  }

  // execute after the view is checked
  ngAfterViewChecked() {
    if (this.isToogleActive()) {
      this.setActiveValue();
    } else {
      this.setInActiveValue();
    }
  }

  // Getting the Child elements of the mat-slide-toggle element
  private getChildDataNode() {
    return this.el.nativeElement.childNodes[0].childNodes[0];
  }

  // Check either the mat slide toggle is active or not
  private isToogleActive(): boolean {
    let childNode = this.getChildDataNode();
    // get the input element aria-checked attribute value
    let inputValue =  childNode.childNodes[0].getAttribute('aria-checked');
    const returnValue = (inputValue && inputValue == 'true')? true : false;
    return returnValue;
  }

  // Setting the active label text
  private setActiveValue() {
    let childNode = this.getChildDataNode();
    // Adding the data-content attribute on the div and setting the value of ActiveLabel in it
    // data-content property value is setting in the css as content: attr(data-content) to display label text
    this._renderer.setAttribute(childNode, 'data-content', this.activeLabel);

    // Adding and removing the text alignment class with respect to toggle state
    this._renderer.removeClass(childNode, 'text-right');
    this._renderer.addClass(childNode, 'text-left');
  }

  // Setting the inActive label text
  private setInActiveValue() {
    let childNode = this.getChildDataNode();
    // Adding the data-content attribute on the div and setting the value of inActiveLabel in it
    // data-content property value is setting in the css as content: attr(data-content) to display label text
    this._renderer.setAttribute(childNode, 'data-content', this.inActiveLabel);
    this._renderer.removeClass(childNode, 'text-left');
    this._renderer.addClass(childNode, 'text-right');
  }

}
