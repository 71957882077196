import { PasswordChangePopupComponent } from './password-change-popup/password-change-popup.component';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { V1MenuService } from '@app/core';
import { UtilityService } from '@app/core/services/utility.service';
import { DOCUMENT } from '@angular/common';
import { V1UtilitiesService } from '@app/core/services/v1-utilities.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApienvService } from '@app/core/services/apienv.service';

@Component({
  selector: 'app-external-screen',
  templateUrl: './external-screen.html',
  styleUrls: ['./external-screen.scss']
})
export class ExternalScreenComponent implements OnInit, AfterViewInit {
  @ViewChild('externalHtml') externalHtmldiv;
  _title;
  _buttons = [];
  dialog: MatDialogRef<any>;
  userNenvObj;
  constructor(
    public authenticationService: AuthenticationService,
    public v1Utility: V1UtilitiesService,
    public _utilityService: UtilityService,
    private v1MenuSer: V1MenuService,
    private render: Renderer2,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<ExternalScreenComponent>,
    private _http: HttpClient,
    private env: ApienvService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.action && data.action == 'changePass') {
      this._title = 'Change your password';
    }
  }
  ngAfterViewInit(): void {

  }
  ngOnInit() {
    this.LoadScript(this._title);
    this.userNenvObj = {
      environment: this.authenticationService.currentUserValue.designation,
      username: this.authenticationService.currentUserValue.masterUser
    }
  }
  LoadScript(title) {
    // const s = this.document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = '//external.script.com/script.js';
    // const __this = this; //to store the current instance to call
    //                      //afterScriptAdded function on onload event of
    //                      //script.
    // s.onload = function () { __this.afterScriptAdded(); };
    // this.changePasdiv.nativeElement.appendChild(s);
    if (title === 'Change your password') {
      this.v1Utility.LoadChangePassScript({
        UserName: this.authenticationService.currentUserValue.masterUser
      }).subscribe(x => {
        let node = this.render.createElement('div');
        node.innerHTML = x;

        // this.render.selectRootElement()
        // node.type = 'text/javascript';
        // node.async = false;
        // node.charset = 'utf-8';
        // const head = this.document.getElementsByTagName('head')[0];
        this._buttons = [];
        let btnAction = this.CreateBtn('change_password_form_action', node, true);
        let btnClose = this.CreateBtn('change_password_form_close', node, true);
        if (btnAction) {
          this._buttons.push(btnAction);
        }
        if (btnClose) {
          this._buttons.push(btnClose);
        }
        this.applycss(node);
        this.render.appendChild(this.externalHtmldiv.nativeElement, node);
        // this.externalHtmldiv.nativeElement.innerHTML=x;
        // head.appendChild(_jq);
        // console.log("Jquery::::", this._buttons);
      });
    }
  }
  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };
    if (typeof (window['functionFromExternalScript']) === 'function') {
      window['functionFromExternalScript'](params);
    }
  }
  _close() {
    this.preventShowingChromePassUpdate()
    this.dialogRef.close();
  }
  CreateBtn(id, node, remove?) {
    const _btn = node.querySelector('#' + id);
    if (_btn) {
      let tempBtns = {
        label: _btn.innerText,
        id: id
      };
      if (remove) this.render.removeChild(node.nativeElement, _btn);
      return tempBtns;
    } else {
      return undefined;
    }
  }

  selectedValue(name, node, value) {
    const _radio = node.querySelectorAll(`input[name="${name}"]`);
    if (_radio) {
      for (let i = 0; i < _radio.length; i++) {
        if (_radio[i].defaultValue === value) {
          _radio[i].checked = true;
        }
      }
    }
  }

  addOnChangeEvent(name, node, func, rootField) {
    const _field = node.querySelectorAll(`${rootField}[name="${name}"]`);
    if (_field) {
      for (let i = 0; i < _field.length; i++) {
        _field[i].onchange = e => func(e, name, this);
      }
    }
  }

  addOnClickEvent(id, node, func, isArray) {
    if (isArray) {
      const _field = node.querySelectorAll(id);
      if (_field) {
        for (let i = 0; i < _field.length; i++) {
          _field[i].onclick = e => func(e, this, node, id);
        }
      }
    } else {
      const _field = node.querySelector(id);
      if (_field) _field.onclick = () => func();
    }
  }

  onbtnClick(id) {
    if (id == 'change_password_form_close') {
      this._close();
    } else if (id == 'change_password_form_action') {
      this.changePassReq();
    }
  }
  applycss(_node) {
    let nodes = _node.getElementsByTagName("input");
    if (nodes && nodes.length > 0) {
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].classList.add('customcss');
      }
    }
  }
  changePassReq() {
    let form = this.externalHtmldiv.nativeElement.querySelector('form');
    let formData = new FormData(form);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    // var json = JSON.stringify(object);

    // console.log("Form Data", formData, object);
    this.v1Utility.LoadChangePassScript(object).subscribe(x => {
      let tempObj = JSON.parse(x)
      if (tempObj && tempObj.errors) {
        // console.log("Response Back", tempObj);
        let error = this.externalHtmldiv.nativeElement.querySelector('div.error');
        // console.log("error element Find", error);
        if (error) {
          let msg = tempObj["errors"].join("<br>");
          error.style.display = "inherit";
          error.innerHTML = msg;
          setTimeout(() => {
            error.style.display = "none";
          }, 5000);
        }
      } else {
        this.dialog = this._dialog.open(PasswordChangePopupComponent, {
          width: '27%',
          height: '25%',
          disableClose: true
        });
        // let success=this.externalHtmldiv.nativeElement.querySelector('div.success');
        // success.innerHTML=("<b>Your password has been successfully changed.</b><hr>You must now relogin to SphereWMS.<hr><button id=\'logoutbtn\' type=\'button\' >Login</button>");
        // let btn = this.externalHtmldiv.nativeElement.querySelector('#logoutbtn');
        // btn.addEventListener("click", this.logout.bind(this) );
        // success.style.display="inherit";
      }
    })
    //     UserName: aatisamahmed
    // old_password: asdfasfasddfasdf
    // new_password_1: asdfasfdasdfasdfadsf
    // new_password_2: asdfasfasddfasdfasdf
  }
  preventShowingChromePassUpdate() {
    this.render.removeChild(this.externalHtmldiv.nativeElement, this.externalHtmldiv.nativeElement);
  }
}
