import { AfterContentInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import {TabLayoutService} from './tab-layout.service';

@Directive({
  selector: '[TabLayoutHead]'
})
export class TabLayoutDirective implements AfterContentInit {
  constructor(private _el: ElementRef,private renderer:Renderer2,private service:TabLayoutService) { }
  ngAfterContentInit() {
    // contentChildren is set
    // console.log("Tabs Panel Area",this._el);
    this.renderer.addClass(this._el.nativeElement.children[0].children[0],"active");
    this.service.isFirst=true;
    this.service.isLast=false;
    //this.renderer.addClass(this._el.nativeElement.children[0],"active");
    this.service.statusToCompleteEvent.subscribe(x=>{
          this.SetStausToComplete(x);
    });
    this.service.NavigateTo.subscribe(x=>{
      this.NavigateTo(x);
    });
  }
  SetStausToComplete(x: any) {
    //tabCompleted
    if(x && x.length>0){
      for(let i=0; i<this._el.nativeElement.children.length;i++){
        //this.renderer.removeClass(this._el.nativeElement.children[i],"active");
        if(this._el.nativeElement.children[i].children.length>0 && this._el.nativeElement.children[i].children[0].localName=='a' && this._el.nativeElement.children[i].children[0].id==(x+'-tab')){
          this.renderer.addClass(this._el.nativeElement.children[i].children[0],"tabCompleted");
        }
      }
    }
  }
  @HostListener('click', ['$event'])
    onButtonClick(event: any) {
    // Logs the id of the element
    // where the event is originally invoked.\

    if(event.target.localName=='a' && event.target.id && event.target.id!=''){
      // console.log(event,"target Id",event.target.id);
      this.DeActivateTab();
      this.renderer.addClass(event.target,"active");
      this.service.tabClicked.emit(event.target.id);
    this.UpdateisFirstAndLast();
      //this.renderer.addClass(event.target,"active");
    }
  }
  DeActivateTab(){
    for(let i=0; i<this._el.nativeElement.children.length;i++){
      //this.renderer.removeClass(this._el.nativeElement.children[i],"active");
      if(this._el.nativeElement.children[i].children.length>0 && this._el.nativeElement.children[i].children[0].localName=='a'){
        this.renderer.removeClass(this._el.nativeElement.children[i].children[0],"active");
      }
    }
  }
  NavigateTo(position){
    let obj=this.GetActiveTab(position);
    this.DeActivateTab();
    this.renderer.addClass(this._el.nativeElement.children[obj.position].children[0],"active");
    this.service.tabClicked.emit(obj.Id);
    this.UpdateisFirstAndLast();
  }
  GetActiveTab(position):{position,Id}{
    for(let i=0; i<this._el.nativeElement.children.length;i++){
      //this.renderer.removeClass(this._el.nativeElement.children[i],"active");
      if(this._el.nativeElement.children[i].children.length>0 && this._el.nativeElement.children[i].children[0].localName=='a'){
        for(let l=0;l<this._el.nativeElement.children[i].children[0].classList.length;l++){
          if(this._el.nativeElement.children[i].children[0].classList[l]=='active'){
            if(position=='previous'){
              if(i>0){
                return {position:i-1,Id:this._el.nativeElement.children[i-1].children[0].id};
              }else{
                return {position:i,Id:this._el.nativeElement.children[i].children[0].id};
              }
            }else if(position=='next') {
              if(i>=this._el.nativeElement.children.length-1){
                return {position:i,Id:this._el.nativeElement.children[i].children[0].id};
              }else{
                return {position:i+1,Id:this._el.nativeElement.children[i+1].children[0].id};
              }
            }else if(position=='current'){
              return {position:i,Id:this._el.nativeElement.children[i].children[0].id};
            }
          }
        }
      }
    }
  }
  UpdateisFirstAndLast(){
    let currentState=this.GetActiveTab('current');
    if(currentState.position==0){
     this.service.isFirst=true;
     this.service.isLast=false;
    }else if((this._el.nativeElement.children.length-1)==currentState.position){
      this.service.isFirst=false;
      this.service.isLast=true;
    }else{
      this.service.isFirst=false;
     this.service.isLast=false;
    }
  }
}
