import { Component, OnInit, Input, Attribute } from '@angular/core';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'screen-title',
  templateUrl: './screen-title.component.html',
  styleUrls: ['./screen-title.component.scss']
})
export class ScreenTitleComponent implements OnInit {
  currentSelectedClientName='';
  constructor(public AuthService:AuthenticationService,@Attribute('forNavbar') forNavbar: boolean) {
    //this.classes=classes;
   this.forNavBar=forNavbar;
   this.AuthService.clinetNameChanged.subscribe(x=>{
     this.currentSelectedClientName=x;
   });
  }
  hideTitle=false;
  @Input('hide-title') set HideTitle(value){
    if(value=='true' || value==true){
        this.hideTitle=true;
    }else{
      this.hideTitle=false;
    }
  }
  @Input('title') _title;
  @Input('breadCrumb') _breadCrumb;
  @Input('colSize') _col='col-6';
  forNavBar=false;
  //titleWithTabPills
  ngOnInit(): void {
    // console.log("Title Hide variable",this.hideTitle,"From :",this._title);
    this.currentSelectedClientName=this.AuthService.clientNameFromMenu;
    //console.warn("Current Client Name:",this.currentSelectedClientName,this.AuthService);
  }

}
