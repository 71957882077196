import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, UtilityService, V1MenuService, V1UtilitiesService } from '@app/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-grid-export',
  templateUrl: './grid-export.component.html',
  styleUrls: ['./grid-export.component.scss']
})


export class GridExportComponent implements OnInit {

UpdateGrid: EventEmitter<any> = new EventEmitter<any>();

  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  uniqueid;
  routeagaintogrid: Observable<any>
  ddloptions:any[] = []
  title;
  columnsToDisplay = [
  ];
  public isMenu: boolean = false;



  constructor(public dialogRef: MatDialogRef<GridExportComponent>,private v1Utility:V1UtilitiesService,
    private _activatedRoute: ActivatedRoute,
    private auth:AuthenticationService,
    private _utilityService:UtilityService,public v1menuservice:V1MenuService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log(this.uniqueid,data)
      this.uniqueid = data[0];

     }
     items=[];

    ngOnInit(): void {


    }

    selectFile(event): void {
      this.selectedFiles = event.target.files;
    }

    upload(): void {

      this.progress = 0;

      this.currentFile = this.selectedFiles.item(0);
      this.v1Utility.upload(this.currentFile,this.uniqueid).subscribe(
        event => {
          // console.log("File Uploaded",event);
          let filename =event.split('|');
        //    if (event.type === HttpEventType.UploadProgress) {
        //      this.progress = Math.round(100 * event.loaded / event.total);
        //  } else if (event instanceof HttpResponse) {
        //     this.message = event.body.message;
        //     this.fileInfos = this.v1Utility.getFiles();
        //    }
        this.auth.gemxPost ({
          gconn: this.auth.gConn,
          gcmd: '[RAW]C:\\'+filename[2]
        }).subscribe(x=>{
          this.dialogRef.close({state:'menu'})

        });

        },

        err => {
        //    this.progress = 0;
        //    this.message = 'Could not upload the file!';
        //  this.currentFile = undefined;
          // console.log("Error From File Uploading",err);
        });

      this.selectedFiles = undefined;


      // this._utilityService.navigate('filesToenvironment',{cmd:cmd},true);


      // window.location.reload();

      // this._activatedRoute.queryParams.subscribe(param => {
      //   console.log(param)
      //   if (param && param.cmd) {
      //     this.auth.gemxPost({
      //       gconn: this.auth.gConn,
      //       gcmd: '[RAW]'
      //     }).subscribe(x=>{
      //       console.log("Got Data",x);
      //       this._activatedRoute.queryParams.subscribe(param => {
      //         console.log(param)
      //         if (param && param.cmd) {
      //           this.auth.gemxPost({
      //             gconn: this.auth.gConn,
      //             gcmd: '[MENU]0002'
      //           })
      //         }
      //       });
      //     });
      //   }
      // });

    }

    NewFileParseAndRenderGrid(element,afterSearching?){
      let tempGrid=this.auth.ParseGrid(element);
      let v3Config=this.auth.GetV3FormatedGrid(tempGrid,0);
      // console.log("Files to Env Grid Data",tempGrid,v3Config);
      this.columnsToDisplay=[];
      setTimeout(() => {
      this.columnsToDisplay=v3Config.cols;
      this.items=v3Config.data;
      }, 100);
      let UpdatedGrid= { tempGrid, v3Config }
      this.UpdateGrid.emit(UpdatedGrid);

      // console.log(this.UpdateGrid);


    }



  }


