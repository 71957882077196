import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, clsv1Response, ScreenRenderer, UtilityService, V1MenuService, V1UtilitiesService } from '@app/core';
import { LoaderService } from '@app/core/services/loader.service';
import { screen_rendered_functions } from '@app/core/services/utils/parse-screen-general-functions';

@Component({
  selector: 'delete-entry-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent extends screen_rendered_functions implements OnInit {

  type: any;
  timeOut: any;
  buttonText: any;
  tempResponse;
  @ViewChild('btnYes') btnYes: ElementRef;
  @ViewChild('btnNo') btnNo: ElementRef;
  constructor(public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _utilityService: UtilityService,
    public _dialog: MatDialog,
    public auth:AuthenticationService,
    public v1Utility:V1UtilitiesService,
    public  v1Menu:V1MenuService,
    public loaderSer:LoaderService) {
      super(_utilityService,_dialog,auth,v1Utility,v1Menu,loaderSer);
      this.disableECSKey=true;
      this.type = data.type;
      this.tempResponse=data.body;
    }
    _recodInfo=[];
    _textAreYouSure;
    ngOnInit() {
      // console.log("tempResponse", this.tempResponse);
        this._initializeScreen(this.tempResponse);
    }




    _onComplition(splitedlines?){

      if(this._controls.length==1 && this._controls[0].elements.length>1 && this._controls[0].elements[1].isLookUp && (this._controls[0].elements[0].cValue+'').trim().toLowerCase().includes('to void')){
        this._goBackToMainScreen().subscribe(x=>{
          if(x!=='FAIL'){
            this.dialogRef.close(x);
          }else{
            console.warn("Not able to come back to master screen:",x);
          }
        });

      }

      // console.log("the this._controls.", this._controls);
      if(this._controls.length>3){
        console.clear();
        // console.log("controls", this._controls)
        this._recodInfo=[];
        for(let i=2;i<=this._controls.length;i++){
          this._recodInfo.push(
            this._controls[i].elements.map(el=>{
              return (el.cValue+'').trim();
            })
          );
        }

        // this._recodInfo.push(
        //   this._controls[5].elements.map(el=>{
        //     if(el.cType=="input" && el.cValue==""){
        //       return { type: "reason_code" };
        //     }else{

        //       return (el.cValue+'').trim();
        //     }
        //   })
        // );

        // console.log("record", this._recodInfo)
        let actionBtn=this._controls.find(x=>(x.elements[0].cValue+'').trim().toLowerCase().includes('are you sure?'));

        if(actionBtn){
          // console.log("actionBtn", actionBtn)
          this._textAreYouSure=actionBtn.elements[0].cValue;
          let action=(actionBtn.elements[1].cValue+'').trim().toUpperCase();
          // console.log("action", action)
          setTimeout(() => {
            if(action=='Y'){
              if(this.btnYes){
                this.btnYes.nativeElement?.focus();
              }
            }else if(action=='N'){
              if(this.btnNo){
                this.btnNo.nativeElement?.focus();
              }
            }
          }, 10);
        }
      }

    }

}
