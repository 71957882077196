import { Injectable } from '@angular/core';;
import { empty, forkJoin, from, of } from 'rxjs';
import { environment } from '@env/environment';
import { catchError, filter, map, retry, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
export class AppInitService {
    menu_mapping_config:menuMapping;
    subdomain_account_mapping:clsSubDomainAccountMapping;
  // This method will call at bootstrap
  public init() {
    return forkJoin([from(
          fetch('assets/config/'+this.getAccountSpecificMenuConfigFileName()).then(function(response) {
           return response.json();
           }).catch(notFound=>{
             return new Promise((resolve,reject)=>{
              fetch('assets/config/default-menu-mapping.json').then(function(res){
                resolve(res.json())
              })
             })
           })
          ).pipe(
            map((config) => {
            this.menu_mapping_config=config;
            // console.log("menu-mapping.json Got:",config);
            return
          })
          ).toPromise(),
        from(
          fetch('assets/config/account-domain-access-right.json').then(function(response) {
            return response.json();
            })
          ).pipe(
            map((config) => {
              let _accDmnMapObj=config;
              if(_accDmnMapObj && _accDmnMapObj['mapping-list']){
                this.subdomain_account_mapping=new clsSubDomainAccountMapping(_accDmnMapObj['mapping-list']);
              }else{
                this.subdomain_account_mapping=new clsSubDomainAccountMapping([]);
              }
            // console.log("account-domain-access-right Got:",config);
            return
          })).toPromise()]).toPromise()
  }
  getAccountSpecificMenuConfigFileName(){
    const hostname = window && window.location && window.location.hostname;
    let fileName;
    let isFeatureOrStagin=hostname.split('.').find(x=>x=='v1-redesign');
    if (/^.*localhost.*/.test(hostname) || isFeatureOrStagin) {
         fileName= environment.menuConfigFile;
    } else {
        let parts=hostname.split('.');
        let subdomain;
        for(let i=0;i<parts.length;i++){
            if(parts[i]!=='https://www' && parts[i]!=='http://www' && parts[i]!=='www'){
            subdomain=parts[i];
            break;
            }
        }
        fileName=subdomain+'-menu-mapping.json';
    }
    return fileName;
  }

}
export interface menuMapping{
    _comment,
    menus: [{parent_key,title,values: [{key_header,title_header,editByColName,_comment,values}]}],
    entryEditEnquiryMenuTemplate: [{key,title}]
  }
  export interface InterfaceSubDomainAccountMapping{
    accountAccess,
    sandboxAccountAccess,
    subdomain,
    subdomainsandbox
  }
  export class clsSubDomainAccountMapping  {
    constructor(public subdomain_accounts_mapping:Array<InterfaceSubDomainAccountMapping>){}
    private getSubdomainAssociatedAccountId(subDomainName,forSandBox:boolean){
      return this.subdomain_accounts_mapping.find(x=>x.subdomain==subDomainName)?
      (forSandBox? this.subdomain_accounts_mapping.find(x=>x.subdomain==subDomainName).sandboxAccountAccess :this.subdomain_accounts_mapping.find(x=>x.subdomain==subDomainName).accountAccess):
      undefined;
    }
    private getAssociatedSubdomain(accountId){
      let _prod=this.subdomain_accounts_mapping.find(x=>x.accountAccess==accountId)?.subdomain;
      if(_prod){
        return _prod;
      }else{
        let _sndBox=this.subdomain_accounts_mapping.find(x=>x.sandboxAccountAccess==accountId)?.subdomain;
        let _sndBoxSpecificdomain=this.subdomain_accounts_mapping.find(x=>x.sandboxAccountAccess==accountId)?.subdomainsandbox;
        if(_sndBoxSpecificdomain){
          return _sndBoxSpecificdomain+'.sandbox';
        } else if(_sndBox){
          return _sndBox+'.sandbox';
        } else{
          return undefined;
        }
      }
    }
    private _hasAccessRight(UserAccounts,subDomainName,forSandBox){
      let _accessRight=this.getSubdomainAssociatedAccountId(subDomainName,forSandBox);
      if(_accessRight=='*'){
        return true;
       }else if(_accessRight){
         if(Array.isArray(UserAccounts)){
          return UserAccounts.find(x=>x===_accessRight)?true:false;
         }else{
          return UserAccounts===_accessRight;
         }
       }else{
        return false;
       }
    }
    hasAccessRights(UserAccounts):boolean{
      if (this.isLoacal) {
           return this._hasAccessRight(UserAccounts,'localhost',false);
      }else if(this.isFeatureOrStaging){
        return this._hasAccessRight(UserAccounts,'GitBranches',false)
      } else {
          let parts=window.location.hostname.split('.');
          let subdomain;
          for(let i=0;i<parts.length;i++){
              if(parts[i]!=='https://www' && parts[i]!=='http://www' && parts[i]!=='www'){
              subdomain=parts[i];
              break;
              }
          }
          if(subdomain){
            return this._hasAccessRight(UserAccounts,subdomain,this.isSANDBOX);
          }else{
            return false;
          }
      }
    }
    CheckAccountURLAssociation(selectedAccountId){
      if(this.isFeatureOrStaging || this.isLoacal){
        return { association:true  };
      }else{
        let parts=window.location.hostname.split('.');
        let subdomain;
        for(let i=0;i<parts.length;i++){
            if(parts[i]!=='https://www' && parts[i]!=='http://www' && parts[i]!=='www'){
            subdomain=parts[i];
            break;
            }
        }
        if(subdomain){
         let _mappedAccountId=this.getSubdomainAssociatedAccountId(subdomain,this.isSANDBOX?true:false);
          if(_mappedAccountId==selectedAccountId){
            return { association:true  };
          }else{
            let _associatedSubdomain=this.getAssociatedSubdomain(selectedAccountId);
            if(_associatedSubdomain){
              return { association:false ,isSubdomainExistInConfig:true , subDomain:_associatedSubdomain };
            }else{
              let _parseSubDomain=this.parsedomainNameFromAccountId(selectedAccountId);
              return { association:false ,isSubdomainExistInConfig:false, subDomain:_parseSubDomain };
            }
          }
        }else{
          return { association:false,isSubdomainExistInConfig:false, subDomain:undefined};
        }
      }
    }
    private parsedomainNameFromAccountId(_accountId){
      let split=_accountId.split('-');
      if(split.lenth>1){
        let _envTypArg=(split[1]+'').trim().toLowerCase();
        let _envName=(split[0]+'').trim().toLowerCase();
        if(_envTypArg=='s' || _envTypArg=='d'){
          return _envName+'.sandbox';
        }else{
          return _envName;
        }
      }else{
        return (_accountId+'').trim().toLowerCase();
      }
    }
    get isSANDBOX(){
      return window.location.hostname.split('.').find(x=>x=='sandbox');
    }
    get isFeatureOrStaging(){
      return (window.location.hostname.split('.').find(x=>x=='v1-redesign')?true:false);
    }
    get isLoacal(){
      return /^.*localhost.*/.test(window.location.hostname);
    }
  }
