<div class="container">
    <div class="general-popup-header">
        <div class="row">
            <div class="col-lg-11 col-md-11 col-sm-10 col-10">
                <h2 class="">{{_title}}</h2>
            </div>
            <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-1 text-right">
              <img src="/assets/icon/close.svg" class="Cpointer closeIcon" mat-dialog-close>
          </div> -->
        </div>
    </div>
    <div class="general-popup-body">
        <v1-ui [readonly]="false" [_layoutElements]="_layoutElements" [_workAreaId]="_workAreaId"
            [formLabelMinWidth]="_maxInputLabelWidth" [isClickableOptionScreen]="_isOptionSelectionScreen"
            (_onEnterPress)="_onEnterPress($event)" (_onInputfocus)="onInputfocus($event)"
            (_onLookupEnterPress)="_onEnterPress($event,true)" (_onLookupIconClicked)="onLookupInputClicked($event)"
            (_EscBackClicked)="_onEnterPress('[ESC]')">
        </v1-ui>
    </div>

</div>